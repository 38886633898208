import { Link } from '@shopify/polaris'
import { FC } from 'react'
import styled from 'styled-components'

import { deleteToken, getLoginAsUrl } from 'auth'

export const LoginAsUrl: FC = () => {
  const loginAsUrl = getLoginAsUrl()
  const storeName = loginAsUrl?.split('.')[0]
  const url = new URL(window.location.href)
  if (!loginAsUrl) return null

  return (
    <LoggedInAs>
      <Link
        onClick={() => {
          deleteToken()
          window.location.replace(url.pathname)
        }}
      >
        {storeName}
      </Link>
    </LoggedInAs>
  )
}

const LoggedInAs = styled.div`
  display: flex;
  justify-content: center;

  > button {
    background-color: #ff6767;
    color: #000;
  }
`
