import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter, selectShop } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { openExternalUrl } from 'utils'

export const NeedsToEnableExchangeTestDriveBanner: FC = () => {
  const fmt = _(selectFormatter)
  const shop = _(selectShop)

  const [dismissed, setDismissed] = useState(
    sessionStorage.getItem('rz-needs-to-enable-exchange-test-drive-banner-dismissed') === 'true',
  )

  if (!shop.needsToEnableExchangeTestDrive || dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.needsToEnableExchangeTestDriveTitle')}
      tone="critical"
      action={{
        content: fmt('banners.needsToEnableExchangeTestDriveActionButton'),
        onAction: () => openExternalUrl('https://help.shopify.com/en/manual/organization-settings/test-drive'),
      }}
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-needs-to-enable-exchange-test-drive-banner-dismissed', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.needsToEnableExchangeTestDriveMessage"
          defaultMessage="Shopify Plus customers are required to enable the exchange test drive feature before the exchange integration with Shopify will work properly.  Your returns with exchanges will not sync with Shopify until this is done."
        />
      </p>
    </Banner>
  )
}
