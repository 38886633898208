import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useShippingSettingsQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

const ShippingSettingsPage = lazy(() => import('./ShippingSettingsPage'))

export const ShippingSettingsPageLoader: FC = () => {
  const [query] = useCommonData(useShippingSettingsQuery, 'shipping')
  const loaded = _(selectDataLoaded('shipping'))
  return (
    <>
      {(query.fetching || query.stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <ShippingSettingsPage />
      </PageRevealer>
    </>
  )
}
