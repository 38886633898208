import { useEffect } from 'react'
import { GlobalSlice } from 'store/global/global.slice'
import { storeData } from 'store/global/global.thunks'
import { useAppDispatch } from 'store/hooks'
import { UseQueryArgs, UseQueryResponse } from 'urql'

import { CommonDataQuery, CommonDataQueryVariables, useCommonDataQuery } from 'gql'

type DataId = GlobalSlice['loaded'][number]
type UseQueryHook<T extends object, U extends object> = (
  options: Omit<UseQueryArgs<U>, 'query'>,
) => UseQueryResponse<T, U>
type QueryOptions<U extends object = Record<string, never>> = Omit<UseQueryArgs<U>, 'query'>

const DEFAULT_OPTIONS: QueryOptions = {
  requestPolicy: 'cache-and-network',
}

/**
 * Invokes useCommonDataQuery, stores shop and user data, and returns response.
 */
export function useCommonData(): UseQueryResponse<CommonDataQuery, CommonDataQueryVariables>

/**
 * Invokes query hook, stores shop and user data, and returns the value returned by the query hook.
 */
export function useCommonData<T extends object = object, U extends object = object>(
  useQueryHook: UseQueryHook<T, U>,
  name?: Exclude<GlobalSlice['loaded'][number], 'common'>,
): UseQueryResponse<T, U>

/**
 * Invokes query hook with options passed, stores shop and user data, and returns the value returned by the query hook.
 */
export function useCommonData<T extends object = object, U extends object = object>(
  useQueryHook: UseQueryHook<T, U>,
  options: QueryOptions<U>,
  name?: Exclude<GlobalSlice['loaded'][number], 'common'>,
): UseQueryResponse<T, U>

export function useCommonData<T extends object = object, U extends object = object>(
  useQueryHook?: UseQueryHook<T, U>,
  optionsOrName: QueryOptions<U> | DataId = 'common' as const,
  name: DataId = 'common' as const,
) {
  const dispatch = useAppDispatch()
  const response = useQueryHook
    ? useQueryHook(
        typeof optionsOrName === 'object'
          ? { ...DEFAULT_OPTIONS, ...optionsOrName }
          : (DEFAULT_OPTIONS as QueryOptions<U>),
      )
    : useCommonDataQuery(DEFAULT_OPTIONS)
  const result = response[0]
  useEffect(() => {
    dispatch(storeData(result, typeof optionsOrName === 'object' ? name : optionsOrName))
  }, [result.data, result.error])
  return response
}
