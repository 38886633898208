import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { useParams } from 'react-router-dom'

import { Skeletons } from 'common/Skeletons'
import { useCommonData } from 'common/useCommonData'
import { useReturnQuery } from 'gql'

const ReturnDetailPage = lazy(() => import('./ReturnDetailPage'))

export const ReturnDetailPageLoader: FC = () => {
  const params = useParams()
  const returnId = parseInt(params.id ?? '0', 10)
  const [query] = useCommonData(useReturnQuery, { variables: { returnId } })
  return (
    <>
      {!query.data?.getReturn && <Skeletons />}
      {(query.fetching || query.stale) && <Loading />}
      <ReturnDetailPage ret={query.data?.getReturn} />
    </>
  )
}
