import { Select, TextField } from '@shopify/polaris'
import { Field } from '@shopify/react-form'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import {
  selectRuleActionOptionsReturnTypes,
  selectRuleActionOptionsShippingTypes,
  selectRuleActions,
} from 'store/rules/rules.selectors'
import styled from 'styled-components'

import { RuleAction, RuleActionOption, useWarehousesQuery } from 'gql'

const PLACEHOLDER_OPTIONS = {
  EMPTY: { label: '', value: '' },
}

interface TakeActionRowProps {
  action: Field<any>
  actionOption: Field<any>
  warehouseId: Field<any>
  windowDays: Field<any>
  value: Field<any>
  showAdjustWindowAction: boolean
  showApprovalActions: boolean
  showDoNotRequireShippingAction: boolean
}

const TakeActionRow: FunctionComponent<TakeActionRowProps> = ({
  action,
  actionOption,
  warehouseId,
  windowDays,
  value,
  showAdjustWindowAction,
  showApprovalActions,
  showDoNotRequireShippingAction,
}) => {
  const fmt = _(selectFormatter)

  const ruleActions = _(selectRuleActions)
  const ruleActionOptionReturnTypes = _(selectRuleActionOptionsReturnTypes)
  const ruleActionOptionShippingTypes = _(selectRuleActionOptionsShippingTypes)
  const [{ data }] = useWarehousesQuery()
  const warehouses = data?.warehouses

  let actionSelectOptions = Object.values(ruleActions)

  if (!showAdjustWindowAction)
    actionSelectOptions = actionSelectOptions.filter((a) => a.value !== RuleAction.AdjustWindow)

  if (!showApprovalActions)
    actionSelectOptions = actionSelectOptions.filter(
      (a) => !(a.value == RuleAction.RequireApproval || a.value == RuleAction.DoNotRequireApproval),
    )

  if (!showDoNotRequireShippingAction)
    actionSelectOptions = actionSelectOptions.filter((a) => a.value !== RuleAction.DoNotRequireReturnShipping)

  return (
    <Content>
      <Select
        label={<FormattedMessage id="takeActionRow.thenTakeAction" defaultMessage="Then take this action" />}
        name="action"
        options={actionSelectOptions}
        {...action}
      />

      {[RuleAction.DenyReturn, RuleAction.AdjustWindow, undefined].includes(action.value) && (
        <Select
          name="actionOption"
          label="&nbsp;"
          options={Object.values(ruleActionOptionReturnTypes)}
          {...(actionOption as any)}
        />
      )}

      {action.value === RuleAction.SetFee && (
        <>
          <Select
            name="actionOption"
            label="&nbsp;"
            options={[
              {
                label: fmt('actionOptions.returnTypes.refunds'),
                value: RuleActionOption.Refund,
              },
              {
                label: fmt('actionOptions.returnTypes.exchanges'),
                value: RuleActionOption.Exchange,
              },
              {
                label: fmt('actionOptions.returnTypes.storeCredits'),
                value: RuleActionOption.Credit,
              },
              {
                label: fmt('actionOptions.returnTypes.allReturnTypes'),
                value: RuleActionOption.All,
              },
            ]}
            {...(actionOption as any)}
          />
          <TextField label="&nbsp;" name="value" placeholder={fmt('global.fee')} autoComplete="off" {...value} />
        </>
      )}

      {action.value === RuleAction.SetShippingFee && (
        <>
          <Select
            name="actionOption"
            label="&nbsp;"
            options={[
              {
                label: fmt('actionOptions.shippingTypes.prepaidShipping'),
                value: RuleActionOption.Free,
              },
              {
                label: fmt('actionOptions.shippingTypes.customerPaidShipping'),
                value: RuleActionOption.Paid,
              },
              {
                label: fmt('actionOptions.shippingTypes.customerSelfShip'),
                value: RuleActionOption.Self,
              },

              {
                label: fmt('actionOptions.shippingTypes.allShippingMethods'),
                value: RuleActionOption.All,
              },
            ]}
            {...(actionOption as any)}
          />
          <TextField label="&nbsp;" name="value" placeholder={fmt('global.fee')} autoComplete="off" {...value} />
        </>
      )}

      {action.value === RuleAction.DenyShipping && (
        <Select
          name="actionOption"
          label="&nbsp;"
          options={Object.values(ruleActionOptionShippingTypes)}
          {...(actionOption as any)}
        />
      )}

      {action.value === RuleAction.ShipToWarehouse && (
        <Select
          label="&nbsp;"
          name="warehouseId"
          options={
            warehouses
              ? Object.values(PLACEHOLDER_OPTIONS).concat(
                  warehouses.map((w) => ({
                    label: w?.name ?? '',
                    value: w?.id ?? '',
                  })),
                )
              : Object.values(PLACEHOLDER_OPTIONS)
          }
          {...warehouseId}
        />
      )}

      {action.value === RuleAction.AdjustWindow && (
        <TextField
          label={<FormattedMessage id="takeActionRow.numberOfDays" defaultMessage="Number of days" />}
          name="windowDays"
          placeholder={fmt('global.days')}
          autoComplete="off"
          {...windowDays}
        />
      )}
    </Content>
  )
}

export default TakeActionRow

const Content = styled.div`
  display: flex;
  gap: var(--p-space-400);
  > * {
    flex: 1 1 auto;
  }
`
