import { Loading } from '@shopify/polaris'
import { FC, useState } from 'react'

import { useCommonData } from 'common/useCommonData'
import { Dashboard } from 'dashboard/Dashboard'
import { useDashboardQuery } from 'gql'

import { getToday } from './DateRangePicker'

// const Dashboard = lazy(() => import('dashboard/Dashboard'))

export const DashboardLoader: FC = () => {
  const today = getToday()

  const [since, setSince] = useState<Date>(new Date(new Date(today).setUTCDate(today.getUTCDate() - 30)))
  const [until, setUntil] = useState<Date>(today)

  const [result] = useCommonData(
    useDashboardQuery,
    {
      variables: {
        startDate: since.toISOString(),
        endDate: until.toISOString(),
      },
    },
    'dashboard',
  )

  return (
    <>
      {(result.fetching || result.stale) && <Loading />}
      <Dashboard
        dashboardMetrics={result.data?.dashboardMetrics}
        since={since}
        until={until}
        setSince={setSince}
        setUntil={setUntil}
      />
    </>
  )
}
