import { AppliedFilterInterface, Button, ChoiceList, LegacyFilters, Popover } from '@shopify/polaris'
import { SortIcon } from '@shopify/polaris-icons'
import { endOfDay, startOfDay } from 'date-fns'
import { FunctionComponent, useEffect, useState } from 'react'
import { selectAllFilterColumns, selectSortOptions, selectStatusFilterOptions } from 'store/filters/filters.selectors'
import { selectFormatter, selectShop } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { ArchiveFilter, ResolvedStatus, ReturnType } from 'gql'
import { LocaleKey } from 'intl/intl'
import { getStages, isEmpty, toNumber, toString } from 'utils'

import { DateFilter, getDateLabel, IDateFilter } from './DateFilter'

export const FILTERS_STORAGE_KEY = 'rz-filters-v6'
export const COLUMNS_STORAGE_KEY = 'rz-columns-v2'
export type ColumnType =
  | 'orderDate'
  | 'customerEmail'
  | 'returnType'
  | 'shippingMethod'
  | 'stage'
  | 'resolvedStatus'
  | 'returnDate'
  | 'warehouseName'
  | 'trackingNumber'
  | 'status'
  | 'labelCost'
  | 'deliveryStatus'
  | 'deliveryDate'
  | 'unitQuantity'
  | 'stageUpdatedDate'
  | 'returnValueAmount'

export const INITIAL_COLUMNS: ColumnType[] = [
  'orderDate',
  'customerEmail',
  'returnType',
  'stage',
  'resolvedStatus',
  'returnDate',
]

export type SortType =
  | '-orderDate'
  | 'orderDate'
  | 'stage'
  | '-returnDate'
  | 'returnDate'
  | 'warehouseName'
  | 'deliveryStatus'
  | 'deliveryDate'
  | '-deliveryDate'
  | 'shippingMethod'

const RETURNS_PER_PAGE = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
]

type IStatus = ArchiveFilter.Active | ArchiveFilter.Archived | ArchiveFilter.Both

// export enum ShippingMethod {
//   Free = 'free',
//   Paid = 'paid',
//   Self = 'self',
// }

export interface IReturnFilters {
  query: string
  returnItemTypes?: ReturnType[]
  // shippingMethod?: ShippingMethod[]
  stages: number[]
  resolvedStatus: ResolvedStatus[]
  status: IStatus
  returnDate: IDateFilter
  orderDate: IDateFilter
  stageUpdatedDate: IDateFilter
  perPage: number
  currentPage: number
  sortBy: SortType
}

export const INITIAL_FILTERS: IReturnFilters = {
  query: '',
  returnItemTypes: [],
  // shippingMethod: [],
  stages: [],
  resolvedStatus: [],
  status: ArchiveFilter.Active,
  returnDate: {
    since: null,
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
  orderDate: {
    since: null,
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
  stageUpdatedDate: {
    since: null,
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
  currentPage: 0,
  perPage: toNumber(RETURNS_PER_PAGE[0].value),
  sortBy: 'stage',
}

interface ReturnFiltersProps {
  filters: IReturnFilters
  setFilters: (filters: IReturnFilters) => void
  columns: string[]
  setColumns: (value: never[]) => void
}

export const ReturnsFilterComponent: FunctionComponent<ReturnFiltersProps> = ({
  filters,
  setFilters,
  columns,
  setColumns,
}) => {
  const fmt = _(selectFormatter)
  const allFilterColumns = _(selectAllFilterColumns)
  const sortOptions = _(selectSortOptions)
  const statusFilterOptions = _(selectStatusFilterOptions)
  const filterOptions = []
  const appliedFilters: AppliedFilterInterface[] = []
  const shop = _(selectShop)
  const [sortOpened, setSortOpened] = useState(false)
  // const [columnsActive, setColumnsActive] = useState(false)

  // const toggleColumnsPopover = useCallback(() => setColumnsActive((active) => !active), [])
  const onClearAllFilters = () => {
    setFilters({ ...INITIAL_FILTERS })
  }
  // Query
  const [searchQuery, setSearchQuery] = useState(filters.query)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery !== filters.query) {
        setFilters({ ...filters, query: searchQuery })
      }
    }, 200)
    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery, filters.query])

  // Return Type
  const returnItemTypes = [ReturnType.Refund, ReturnType.Exchange, ReturnType.Credit]
  const returnTypeOptions = returnItemTypes.map((rt) => ({
    value: rt,
    label: fmt(`returnType.${rt}.name` as LocaleKey),
  }))
  const setReturnType = (returnItemTypes: ReturnType[]) => {
    setFilters({ ...filters, returnItemTypes })
  }
  filterOptions.push({
    key: 'returnType',
    label: fmt('filters.columns.returnType'),
    filter: (
      <ChoiceList
        title={fmt('filters.columns.returnType')}
        titleHidden
        choices={returnTypeOptions}
        selected={filters.returnItemTypes || []}
        onChange={setReturnType}
        allowMultiple
      />
    ),
    shortcut: false,
  })
  if (!isEmpty(filters.returnItemTypes)) {
    const labels: { [key: string]: string } = {}
    returnTypeOptions.forEach((o) => (labels[o.value] = o.label))
    const selectedReturnTypes = (filters.returnItemTypes || []).map((val) => labels[val]).join(', ')
    appliedFilters.push({
      key: 'returnType',
      label: `${fmt('filters.columns.returnType')}: ${selectedReturnTypes}`,
      onRemove: () => setReturnType([]),
    })
  }

  // // Shipping Method
  // const shippingMethods = [ShippingMethod.Free, ShippingMethod.Paid, ShippingMethod.Self]
  // const shippingMethodOptions = shippingMethods.map((method) => ({
  //   value: method,
  //   label: fmt(`shippingMethod.${method}`),
  // }))

  // const setShippingMethods = (shippingMethod: ShippingMethod[]) => {
  //   setFilters({ ...filters, shippingMethod })
  // }
  // // ship.push({
  //   key: 'shippingMethod',
  //   label: fmt('filters.columns.shippingMethod'),
  //   filter: (
  //     <ChoiceList
  //       title={fmt('filters.columns.shippingMethod')}
  //       titleHidden
  //       choices={shippingMethodOptions}
  //       selected={filters.shippingMethod || []}
  //       onChange={setShippingMethods}
  //       allowMultiple
  //     />
  //   ),
  //   shortcut: false,
  // })
  // if (!isEmpty(filters.shippingMethod)) {
  //   const selectedShippingMethods =
  //     filters.shippingMethod?.map((val) => shippingMethodOptions.find((o) => o.value === val)?.label).join(', ') ?? ''
  //   appliedFilters.push({
  //     key: 'shippingMethod',
  //     label: `${fmt('filters.columns.shippingMethod')}: ${selectedShippingMethods}`,
  //     onRemove: () => setShippingMethods([]),
  //   })
  // }

  // Stage
  const stages = getStages(shop)
  const setStage = (stages: string[]) => setFilters({ ...filters, stages: stages.map(toNumber) })
  filterOptions.push({
    key: 'stage',
    label: fmt('filters.columns.stage'),
    filter: (
      <ChoiceList
        title="stage"
        titleHidden
        choices={stages}
        selected={filters.stages.map(toString)}
        onChange={setStage}
        allowMultiple
      />
    ),
    shortcut: true,
  })
  if (!isEmpty(filters.stages)) {
    const labels: { [key: string]: string } = {}
    stages.forEach((s) => {
      labels[s.value] = s.label
    })
    const selectedStages = filters.stages.map((val) => labels[val]).join(', ')

    appliedFilters.push({
      key: 'stage',
      label: `${fmt('filters.columns.stage')}: ${selectedStages}`,
      onRemove: () => setStage([]),
    })
  }

  // Resolved
  const resolvedStatusChoices = [
    ResolvedStatus.Unresolved,
    ResolvedStatus.PartiallyResolved,
    ResolvedStatus.Resolved,
  ].map((rs) => ({
    value: rs,
    label: fmt(`resolvedStatus.${rs}` as LocaleKey),
  }))
  const setResolvedStatus = (resolved: ResolvedStatus[]) => {
    setFilters({ ...filters, resolvedStatus: resolved })
  }
  filterOptions.push({
    key: 'resolvedStatus',
    label: fmt('resolvedStatus.label'),
    filter: (
      <ChoiceList
        title={fmt('resolvedStatus.label')}
        titleHidden
        choices={resolvedStatusChoices}
        selected={filters.resolvedStatus}
        onChange={setResolvedStatus}
        allowMultiple
      />
    ),
    shortcut: false,
  })
  if (!isEmpty(filters.resolvedStatus)) {
    const labels: { [key: string]: string } = {}
    resolvedStatusChoices.forEach((s) => {
      labels[s.value] = s.label
    })
    const selectedResolvedChoices = filters.resolvedStatus
      .map((val) => resolvedStatusChoices.find((c) => c.value === val)?.label)
      .join(', ')

    appliedFilters.push({
      key: 'resolvedStatus',
      label: `${fmt('resolvedStatus.label')}: ${selectedResolvedChoices}`,
      onRemove: () => setResolvedStatus([]),
    })
  }

  // Return Date
  const setReturnDate = (theDate: IDateFilter) => {
    setFilters({ ...filters, returnDate: theDate })
  }
  filterOptions.push({
    key: 'returnDate',
    label: fmt('filters.columns.returnDate'),
    filter: <DateFilter currentValue={filters.returnDate} setValue={setReturnDate} />,
    shortcut: true,
  })
  const clearReturnDate = () => {
    setFilters({
      ...filters,
      returnDate: { ...filters.returnDate, since: null },
    })
  }
  if (filters.returnDate.since) {
    appliedFilters.push({
      key: 'returnDate',
      label: getDateLabel(fmt, fmt('filters.columns.returnDate'), filters.returnDate),
      onRemove: clearReturnDate,
    })
  }
  // Order Date
  const setOrderDate = (theDate: IDateFilter) => {
    setFilters({ ...filters, orderDate: theDate })
  }
  filterOptions.push({
    key: 'orderDate',
    label: fmt('filters.columns.orderDate'),
    filter: <DateFilter currentValue={filters.orderDate} setValue={setOrderDate} />,
    shortcut: false,
  })
  const clearOrderDate = () => {
    setFilters({
      ...filters,
      orderDate: { ...filters.orderDate, since: null },
    })
  }
  if (filters.orderDate.since) {
    appliedFilters.push({
      key: 'orderDate',
      label: getDateLabel(fmt, fmt('filters.columns.orderDate'), filters.orderDate),
      onRemove: clearOrderDate,
    })
  }

  // Stage Updated Date
  const setstageUpdatedDatee = (theDate: IDateFilter) => {
    setFilters({ ...filters, stageUpdatedDate: theDate })
  }

  filterOptions.push({
    key: 'stageUpdatedDate',
    label: fmt('filters.columns.stageUpdatedDate'),
    filter: <DateFilter currentValue={filters.stageUpdatedDate} setValue={setstageUpdatedDatee} />,
    shortcut: false,
  })

  const clearstageUpdatedDatee = () => {
    setFilters({
      ...filters,
      stageUpdatedDate: { ...filters.stageUpdatedDate, since: null },
    })
  }
  if (filters.stageUpdatedDate.since) {
    appliedFilters.push({
      key: 'stageUpdatedDate',
      label: getDateLabel(fmt, fmt('filters.columns.stageUpdatedDate'), filters.stageUpdatedDate),
      onRemove: clearstageUpdatedDatee,
    })
  }

  // Status
  const setStatus = (status: IStatus[]) => {
    setFilters({ ...filters, status: status[0] })
  }
  filterOptions.push({
    key: 'status',
    label: fmt('filters.columns.status'),
    filter: (
      <ChoiceList
        title={fmt('filters.columns.status')}
        titleHidden
        choices={Object.values(statusFilterOptions)}
        selected={[filters.status]}
        onChange={setStatus}
      />
    ),
    shortcut: true,
  })
  if (filters.status !== ArchiveFilter.Active) {
    appliedFilters.push({
      key: 'status',
      label: `${fmt('filters.columns.status')}: ${statusFilterOptions[filters.status].label}`,
      onRemove: () => setStatus([ArchiveFilter.Active]),
    })
  }

  // Per Page
  filterOptions.push({
    key: 'perPage',
    label: fmt('filters.showPerPage'),
    filter: (
      <ChoiceList
        title={fmt('filters.perPage')}
        titleHidden
        choices={RETURNS_PER_PAGE}
        selected={[filters.perPage.toString()]}
        onChange={(selected) => {
          setPerPage(selected[0])
        }}
      />
    ),
    shortcut: false,
    hideClearButton: true,
  })

  // Columns
  const setPerPage = (count: string) => {
    setFilters({ ...filters, perPage: toNumber(count) })
  }
  filterOptions.push({
    key: 'columns',
    label: fmt('filters.columns'),
    filter: (
      <ChoiceList
        allowMultiple
        title={`${fmt('filters.columnsToDisplay')}:`}
        choices={allFilterColumns}
        selected={columns}
        onChange={setColumns}
      />
    ),
    shortcut: false,
    hideClearButton: true,
  })

  return (
    <div style={{ padding: '16px', display: 'flex', gap: 'var(--p-space-200)' }}>
      <div style={{ flex: 1 }} data-cy="returns-table-filters">
        <LegacyFilters
          queryPlaceholder={fmt('filters.queryPlaceholder')}
          queryValue={searchQuery}
          filters={filterOptions}
          appliedFilters={appliedFilters}
          onQueryChange={setSearchQuery}
          onQueryClear={() => setSearchQuery('')}
          onClearAll={onClearAllFilters}
        />
      </div>
      <Popover
        active={sortOpened}
        activator={<Button size="large" icon={SortIcon} onClick={() => setSortOpened((opened) => !opened)} />}
        autofocusTarget="first-node"
        preferredAlignment="right"
        onClose={() => setSortOpened(false)}
      >
        <Popover.Pane fixed>
          <Popover.Section>
            <ChoiceList
              title={fmt('filters.sortBy')}
              choices={sortOptions}
              selected={[filters.sortBy]}
              onChange={(selected) => {
                setFilters({ ...filters, sortBy: selected[0] as SortType })
              }}
            />
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    </div>
  )
}
