import styled from 'styled-components'

export const ChartName = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.4;
  color: var(--p-color-icon-secondary);
`
