import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter, selectShop } from 'store/global/global.selectors'
import { showToast } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'

import { useReturnsDeleteTestModeMutation } from 'gql'

type TestReturnBannerProps = {
  refreshReturns?: () => unknown
}

export const TestReturnsBanner: FC<TestReturnBannerProps> = ({ refreshReturns }) => {
  const fmt = _(selectFormatter)
  const dispatch = useAppDispatch()
  const shop = _(selectShop)

  const [, deleteTestModeReturns] = useReturnsDeleteTestModeMutation()

  const [dismissed, setDismissed] = useState(sessionStorage.getItem('rz-test-returns-banner-dismissed') === 'true')

  if (!shop.hasTestModeReturns || dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.hasTestReturnsTitle')}
      tone="info"
      action={{
        content: fmt('banners.hasTestReturnsButton'),
        onAction: () => {
          deleteTestModeReturns({}).then(() => {
            dispatch(showToast(fmt('banners.toast.testReturnsDeleted')))
            refreshReturns?.()
          })
        },
      }}
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-test-returns-banner-dismissed', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.hasTestReturnsMessage"
          defaultMessage="You have created test returns using the Test Mode feature of the customer portal. Clean them up here when you are finished testing."
        />
      </p>
    </Banner>
  )
}
