import { useEffect, useState } from 'react'

export const useElementWidth = <T extends HTMLElement>() => {
  const [element, setElement] = useState<HTMLElement | null>(null)
  const [width, setWidth] = useState<number | undefined>()
  useEffect(() => {
    if (element) {
      setWidth(element.offsetWidth)
      const resizeObserver = new ResizeObserver(() => setWidth(element.offsetWidth))
      resizeObserver.observe(element)
      return () => resizeObserver.disconnect()
    }
  }, [element])
  return [width, setElement, element] as const
}
