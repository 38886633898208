import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useNotificationSettingsQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

const NotificationsPage = lazy(() => import('./NotificationsSettingsPage'))

export const NotificationsSettingsPageLoader: FC = () => {
  const [query] = useCommonData(useNotificationSettingsQuery, 'notifications')
  const loaded = _(selectDataLoaded('notifications'))
  return (
    <>
      {(query.fetching || query.stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <NotificationsPage />
      </PageRevealer>
    </>
  )
}
