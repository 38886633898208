import { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { DashboardQuery } from 'gql'
import { notEmpty } from 'utils'

import { DonutChart } from './DonutChart'

type ActionTakenSlice = Exclude<
  Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['actionTaken'],
  undefined | null
>
type ActionTakenChartProps = {
  data?: ActionTakenSlice
  display: 'units' | 'amount'
}

export const ActionTakenChart: FC<ActionTakenChartProps> = ({ data, display }) => {
  const fmt = _(selectFormatter)
  const countByAction = useMemo(
    () =>
      data && {
        refunds: display === 'units' ? data.refundsCount : parseFloat(data.refundsAmount),
        exchanges: display === 'units' ? data.exchangesCount : parseFloat(data.exchangesAmount),
        storeCredit: display === 'units' ? data.storeCreditCount : parseFloat(data.storeCreditAmount),
        manuallyResolved: display === 'units' ? data.manuallyResolvedCount : parseFloat(data.manuallyResolvedAmount),
      },
    [data, display],
  )
  const enrichedData = useMemo(
    () =>
      countByAction
        ? [
            countByAction.refunds
              ? {
                  name: fmt(`returnType.REFUND.done`),
                  count: countByAction.refunds,
                  paletteIndex: 0,
                }
              : null,
            countByAction.exchanges
              ? {
                  name: fmt(`returnType.EXCHANGE.done`),
                  count: countByAction.exchanges,
                  paletteIndex: 1,
                }
              : null,
            countByAction.storeCredit
              ? {
                  name: fmt(`returnType.CREDIT.done`),
                  count: countByAction.storeCredit,
                  paletteIndex: 2,
                }
              : null,
            countByAction.manuallyResolved
              ? {
                  name: fmt('rma.resolvedManually'),
                  count: countByAction.manuallyResolved,
                  paletteIndex: 3,
                }
              : null,
          ].filter(notEmpty)
        : [],
    [countByAction],
  )
  return (
    <DonutChart
      name={<FormattedMessage id="global.actionTaken" defaultMessage="Action taken" />}
      data={enrichedData}
      money={display === 'amount'}
    />
  )
}
