import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { useCommonData } from 'common/useCommonData'

const CreateReturnPage = lazy(() => import('./CreateReturnPage'))

export const CreateReturnPageLoader: FC = () => {
  useCommonData()
  const loaded = _(selectDataLoaded())
  return (
    <>
      {!loaded && <Loading />}
      <CreateReturnPage />
    </>
  )
}
