import { useRef, useState } from 'react'

export const useDebouncedState = <T,>(defaultValue: T, debounceTime: number = 250) => {
  const timeoutRef = useRef<number>()
  const [value, setValue] = useState<T>(defaultValue)
  return [
    value,
    (arg0: Parameters<typeof setValue>[0], now = false) => {
      window.clearTimeout(timeoutRef.current)
      if (!now) timeoutRef.current = window.setTimeout(() => setValue(arg0), debounceTime)
      else setValue(arg0)
    },
  ] as const
}
