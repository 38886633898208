import { FC, useMemo } from 'react'
import { RETURN_TYPES } from 'rma/values'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { DashboardQuery, ReturnType } from 'gql'
import { notEmpty } from 'utils'

import { DonutChart } from './DonutChart'

type PolicySlice = Exclude<Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['policy'], undefined | null>
type PolicyChartProps = { data: PolicySlice; display: 'units' | 'amount' }

export const PolicyChart: FC<PolicyChartProps> = ({ data, display }) => {
  const fmt = _(selectFormatter)
  const enrichedData = useMemo(
    () =>
      data.filter(notEmpty).map((policy) => ({
        name: fmt(`returnType.${policy.type}.name` as any),
        count: display === 'units' ? policy.count! : parseFloat(policy.amount as string),
        paletteIndex: policy.type ? RETURN_TYPES.indexOf(policy.type as ReturnType) : 3,
      })),
    [data, display],
  )
  return <DonutChart name={fmt('global.returnPolicy')} data={enrichedData} money={display === 'amount'} />
}
