import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { FunctionComponent } from 'react'

import { AnnotatedSection } from 'common/AnnotatedSection'

export const SkeletonSettings: FunctionComponent = () => {
  return (
    <SkeletonPage>
      <Layout>
        <AnnotatedSection title={<SkeletonDisplayText size="small" />} description={<SkeletonBodyText />}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </BlockStack>
        </AnnotatedSection>
        <AnnotatedSection title={<SkeletonDisplayText size="small" />} description={<SkeletonBodyText />}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </BlockStack>
        </AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
