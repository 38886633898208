import { Button, ButtonProps } from '@shopify/polaris'
import { FunctionComponent } from 'react'
import { selectIsBeaconVisible } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { openExternalUrl } from 'utils'

export interface ExternalButtonProps extends ButtonProps {
  url: string
  articleId?: string
}

/**
 * Opening up external link when embedded in shopify admin requires
 * some additional logic.
 **/

export const ExternalButton: FunctionComponent<ExternalButtonProps> = ({
  url,
  articleId,
  children,
  ...buttonConfig
}) => {
  const beaconVisible = _(selectIsBeaconVisible)
  const onClick = (evt?: any): void => {
    evt?.preventDefault()
    if (articleId && beaconVisible) {
      window.Beacon('article', articleId)
    } else {
      openExternalUrl(url)
    }
  }
  return (
    <Button onClick={onClick} {...buttonConfig}>
      {children}
    </Button>
  )
}
export default ExternalButton
