import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { selectFormatter, selectShop } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

export const SelectAPlanBanner: FC = () => {
  const fmt = _(selectFormatter)
  const navigate = useNavigate()

  const shop = _(selectShop)
  const needToSelectAPlan = shop.freeTrialDaysRemaining && shop.freeTrialDaysRemaining > 0 && !shop.subscriptionPlan

  const [dismissed, setDismissed] = useState(sessionStorage.getItem('rz-select-a-plan') === 'true')

  if (!needToSelectAPlan || dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.selectAPlanBannerTitle', { count: shop.freeTrialDaysRemaining })}
      tone="info"
      action={{
        content: fmt('banners.selectAPlanBannerAction'),
        onAction: () => navigate('/config-settings/billing'),
      }}
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-select-a-plan', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.selectAPlanBannerMessage"
          defaultMessage="You are currently on a free trial that includes all features on our PRO plan.  You may select a plan at any time during your free trial."
        />
      </p>
    </Banner>
  )
}
