import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useIntegrationsSettingsQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

const IntegrationSettingsPage = lazy(() => import('./IntegrationsSettingsPage'))

export const IntegrationSettingsPageLoader: FC = () => {
  const [query] = useCommonData(useIntegrationsSettingsQuery, 'integration')
  const loaded = _(selectDataLoaded('integration'))

  return (
    <>
      {(query.fetching || query.stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <IntegrationSettingsPage />
      </PageRevealer>
    </>
  )
}
