import { SystemStage } from 'gql'

export const CHART_PLACEHOLDER_COLOR = '#dddddd'
export const LARGE_CHART_PLACEHOLDER_COLOR = '#eeeeee'

export const STAGE_PALETTE = [
  'rgb(216, 155, 13)', // Reserved for Waiting for Approval
  'rgb(32, 130, 141)', // Reserved for Auto-Approved
  'rgb(22, 166, 121)', // Reserved for Approved
  'rgb(14, 53, 108)', // Reserved for Received
  'rgb(197, 40, 12)', // Reserved for Rejected
  'rgb(242, 117, 34)',
  'rgb(90, 36, 205)',
]

export const getStagePaletteIndex = (itemIndex: number, systemStage: SystemStage | null | undefined) => {
  if (systemStage === SystemStage.PendingApproval) return 0
  if (systemStage === SystemStage.AutomaticallyApproved) return 1
  if (systemStage === SystemStage.Approved) return 2
  if (systemStage === SystemStage.Received) return 3
  if (systemStage === SystemStage.Rejected) return 4
  return itemIndex + 5
}
