import styled, { css } from 'styled-components'

import { DEFAULT_EASING } from 'onboarding/onboarding-styles'

import { ReferenceColor } from './ReferenceColor'
import { CHART_PLACEHOLDER_COLOR } from './dashboard-values'

export const LineChart = styled.div`
  margin-top: var(--p-space-200);
  width: 100%;
  height: 6px;
  position: relative;
`

export const SegmentsPlaceholder = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 3px;
  background: ${CHART_PLACEHOLDER_COLOR};
`

export const Segment = styled.div<{ start: number; end: number; $highlighted: boolean }>`
  position: absolute;
  inset: 0;
  border-radius: 3px;
  overflow: hidden;
  pointer-events: none;
  transition: transform 0.1s ${DEFAULT_EASING};
  ${ReferenceColor} {
    pointer-events: all;
    left: ${(p) => p.start * 100}%;
    right: ${(p) => (1 - p.end) * 100}%;
  }
  ${(p) =>
    p.$highlighted &&
    css`
      transform: scaleY(calc(8 / 6));
    `}
`
