import styled from 'styled-components'

export const DataRows = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: var(--p-space-500);
  display: flex;
  flex-flow: column;
  margin-bottom: calc(var(--p-space-100) * -1);
`
