import { Redirect } from '@shopify/app-bridge/actions'
import { FC, useEffect } from 'react'
import { selectShop, selectShouldAuthenticateUser } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { INDEXED_DB_NAME } from 'config'
import { getShopifyApp } from 'utils/shopify'

export const AuthenticateUser: FC = () => {
  const shop = _(selectShop)
  const url = shop.authenticateUserUrl
  const shouldRedirectToAuthenticate = _(selectShouldAuthenticateUser)
  const app = getShopifyApp()

  useEffect(() => {
    if (shouldRedirectToAuthenticate && !!url && app) {
      // Important to clear out this cache so we don't redirect again on reload
      // since we'd be looking at the same data right away.
      indexedDB.deleteDatabase(INDEXED_DB_NAME)
      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.REMOTE, decodeURIComponent(url))
    }
  }, [shouldRedirectToAuthenticate, app, url])
  return <></>
}

export default AuthenticateUser
