import {
  BlockStack,
  Button,
  DatePicker,
  Icon,
  InlineStack,
  OptionList,
  Popover,
  Scrollable,
  Select,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'
import { ArrowRightIcon, CalendarIcon } from '@shopify/polaris-icons'
import { FC, useEffect, useState } from 'react'
import { FormatDateOptions, useIntl } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/

const isDate = (date: string) => {
  return !isNaN(new Date(date).getUTCDate())
}

const isValidYearMonthDayDateString = (date: string) => {
  return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date)
}

const isValidDate = (date: string) => {
  return date.length === 10 && isValidYearMonthDayDateString(date)
}

const convertLocalDateToUTC = (date: Date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
const convertUTCDateToLocal = (date: Date) => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())

const parseYearMonthDayDateString = (input: string) => {
  const [year, month, day] = input.split('-')
  return new Date(Number(year), Number(month) - 1, Number(day))
}

const toDashedDate = (date: Date) => {
  const year = String(date.getUTCFullYear())
  let month = String(date.getUTCMonth() + 1)
  let day = String(date.getUTCDate())
  if (month.length < 2) month = String(month).padStart(2, '0')
  if (day.length < 2) day = String(day).padStart(2, '0')
  return [year, month, day].join('-')
}

type YearMonth = { year: number; month: number }

const yearMonthDiff = (referenceDate: YearMonth, newDate: YearMonth) => {
  return newDate.month - referenceDate.month + 12 * (referenceDate.year - newDate.year)
}

type DateRange = {
  title: string
  alias: string
  period: () => {
    since: Date
    until: Date
  }
}
type DateRangePickerProps = {
  onChange?: (since: Date, until: Date) => unknown
}

export const getToday = () => new Date(new Date().setUTCHours(0, 0, 0, 0))

const getLastMonday = () => {
  const date = getToday()
  date.setUTCDate(date.getUTCDate() - ((date.getUTCDay() + 6) % 7))
  return date
}
const dayBefore = (date: Date) => new Date(new Date(date).setUTCDate(date.getUTCDate() - 1))
const nextDay = (date: Date) => new Date(new Date(date).setUTCDate(date.getUTCDate() + 1))

export const DateRangePicker: FC<DateRangePickerProps> = ({ onChange }) => {
  const fmt = _(selectFormatter)
  const { formatDate } = useIntl()

  const { mdDown } = useBreakpoints()
  const shouldShowMultiMonth = !mdDown

  const [ranges, setRanges] = useState<DateRange[]>([])
  useEffect(() => {
    setRanges([
      {
        title: fmt('dateRangePicker.today'),
        alias: 'today',
        period: () => {
          const today = getToday()
          return {
            since: today,
            until: nextDay(today),
          }
        },
      },
      {
        title: fmt('dateRangePicker.yesterday'),
        alias: 'yesterday',
        period: () => {
          const today = getToday()
          return {
            since: dayBefore(today),
            until: today,
          }
        },
      },
      {
        title: fmt('dateRangePicker.thisWeek'),
        alias: 'this-week',
        period: () => {
          const lastMonday = getLastMonday()
          return {
            since: lastMonday,
            until: new Date(new Date(lastMonday).setUTCDate(lastMonday.getUTCDate() + 7)),
          }
        },
      },
      {
        title: fmt('dateRangePicker.last7Days'),
        alias: 'last-7-days',
        period: () => {
          const today = getToday()
          return {
            since: new Date(new Date(today).setUTCDate(today.getUTCDate() - 7)),
            until: today,
          }
        },
      },
      {
        title: fmt('dateRangePicker.thisMonth'),
        alias: 'this-month',
        period: () => {
          const today = getToday()
          return {
            since: new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1)),
            until: new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 1)),
          }
        },
      },
      {
        title: fmt('dateRangePicker.last30Days'),
        alias: 'last-30-days',
        period: () => {
          const today = getToday()
          return {
            since: new Date(new Date(today).setUTCDate(today.getUTCDate() - 30)),
            until: today,
          }
        },
      },
      {
        title: fmt('dateRangePicker.lastMonth'),
        alias: 'last-month',
        period: () => {
          const today = getToday()
          return {
            since: new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - 1, 1)),
            until: new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1)),
          }
        },
      },
      {
        title: fmt('dateRangePicker.last90Days'),
        alias: 'last-90-days',
        period: () => {
          const today = getToday()
          return {
            since: new Date(new Date(today).setUTCDate(today.getUTCDate() - 90)),
            until: today,
          }
        },
      },
      {
        title: fmt('dateRangePicker.thisYear'),
        alias: 'this-year',
        period: () => {
          const today = getToday()
          return {
            since: new Date(Date.UTC(today.getUTCFullYear(), 0, 1)),
            until: new Date(Date.UTC(today.getUTCFullYear() + 1, 0, 1)),
          }
        },
      },
      {
        title: fmt('dateRangePicker.last365Days'),
        alias: 'last-365-days',
        period: () => {
          const today = getToday()
          return {
            since: new Date(new Date(today).setUTCDate(today.getUTCDate() - 365)),
            until: today,
          }
        },
      },
      {
        title: fmt('dateRangePicker.lastYear'),
        alias: 'last-year',
        period: () => {
          const today = getToday()
          return {
            since: new Date(Date.UTC(today.getUTCFullYear() - 1, 0, 1)),
            until: new Date(Date.UTC(today.getUTCFullYear(), 0, 1)),
          }
        },
      },
    ])
  }, [])

  const [open, setOpen] = useState(false)

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    title: fmt('dateRangePicker.last30Days'),
    alias: 'last-30-days',
    period: () => {
      const today = getToday()
      return {
        since: new Date(new Date(today).setUTCDate(today.getUTCDate() - 30)),
        until: today,
      }
    },
  })
  const [activeDateRange, setActiveDateRange] = useState<DateRange>(selectedDateRange)

  useEffect(() => {
    if (!open) {
      setSelectedDateRange(activeDateRange)
      setAllowRangeOnSameDate(false)
    }
  }, [open, activeDateRange])

  type InputValues = { since?: string; until?: string }
  const [inputValues, setInputValues] = useState<InputValues>({})

  const [{ month, year }, setDate] = useState({
    month: selectedDateRange.period().since.getUTCMonth(),
    year: selectedDateRange.period().since.getUTCFullYear(),
  })

  const handleStartInputValueChange = (value: string) => {
    setInputValues((prevState) => ({ ...prevState, since: value }))
    if (isValidDate(value)) {
      const newSince = parseYearMonthDayDateString(value)
      setSelectedDateRange((prevState) => {
        return {
          alias: 'custom',
          title: fmt('dateRangePicker.custom'),
          period: () =>
            newSince <= prevState.period().until
              ? { since: newSince, until: prevState.period().until }
              : { since: newSince, until: newSince },
        }
      })
      setAllowRangeOnSameDate(false)
    }
  }

  const handleEndInputValueChange = (value: string) => {
    setInputValues((prevState) => ({ ...prevState, until: value }))
    if (isValidDate(value)) {
      const date = parseYearMonthDayDateString(value)
      const newUntil = new Date(new Date(date).setUTCDate(date.getUTCDate() + 1))
      setSelectedDateRange((prevState) => {
        return {
          alias: 'custom',
          title: fmt('dateRangePicker.custom'),
          period: () =>
            newUntil >= prevState.period().since
              ? { since: prevState.period().since, until: newUntil }
              : { since: newUntil, until: newUntil },
        }
      })
      setAllowRangeOnSameDate(false)
    }
  }

  const handleMonthChange = (month: number, year: number) => {
    setDate({ month, year })
  }

  useEffect(() => {
    onChange?.(selectedDateRange.period().since, selectedDateRange.period().until)
  }, [])

  const apply = () => {
    setActiveDateRange(selectedDateRange)
    onChange?.(selectedDateRange.period().since, selectedDateRange.period().until)
    setOpen(false)
  }

  const cancel = () => setOpen(false)

  useEffect(() => {
    const untilDate = new Date(
      new Date(selectedDateRange.period().until).setUTCDate(selectedDateRange.period().until.getUTCDate() - 1),
    )
    setInputValues({
      since: toDashedDate(selectedDateRange.period().since),
      until: toDashedDate(untilDate),
    })
    const untilYearMonth = {
      month: untilDate.getUTCMonth(),
      year: untilDate.getUTCFullYear(),
    }
    const monthDifference = yearMonthDiff({ year, month }, untilYearMonth)
    if (monthDifference > 1 || monthDifference < 0) setDate(untilYearMonth)
  }, [selectedDateRange])

  const activeDateRangePeriod = activeDateRange.period()
  const dayBeforeActiveUntil = dayBefore(activeDateRangePeriod.until)

  const thisYear = getToday().getUTCFullYear()
  const showYearInButton =
    activeDateRangePeriod.since.getUTCFullYear() !== thisYear || dayBeforeActiveUntil.getUTCFullYear() !== thisYear
  const buttonDateOptions: FormatDateOptions = {
    weekday: 'short',
    year: showYearInButton ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    timeZone: 'utc',
  }
  const buttonLabel =
    activeDateRange.title === fmt('dateRangePicker.custom')
      ? activeDateRange.period().since.getTime() !== dayBeforeActiveUntil.getTime()
        ? formatDate(activeDateRangePeriod.since, buttonDateOptions) +
          ' - ' +
          formatDate(dayBeforeActiveUntil, buttonDateOptions)
        : formatDate(activeDateRangePeriod.since, buttonDateOptions)
      : activeDateRange.title

  const dayBeforeSelectedUntil = dayBefore(selectedDateRange.period().until)

  // This value down here is used to hack Polaris' date picker
  // to make it behave the way it ought to.
  const [allowRangeOnSameDate, setAllowRangeOnSameDate] = useState(false)

  const handleCalendarChange = (start: Date, end: Date) => {
    const newDateRange = ranges.find((range) => {
      return range.period().since.valueOf() === start.valueOf() && range.period().until.valueOf() === end.valueOf()
    }) || {
      alias: 'custom',
      title: fmt('dateRangePicker.custom'),
      period: () => ({
        since: start,
        until: nextDay(end),
      }),
    }
    setSelectedDateRange(newDateRange)
    const startTime = start.getTime()
    const endTime = end.getTime()
    if (startTime === endTime) {
      const sinceTime = selectedDateRange.period().since.getTime()
      const untilTime = dayBeforeSelectedUntil.getTime()
      if (startTime !== sinceTime || startTime !== untilTime) setAllowRangeOnSameDate(true)
      else setAllowRangeOnSameDate((current) => !current)
    }
  }

  const selectedDatePeriod = selectedDateRange.period()

  return (
    <Popover
      active={open}
      autofocusTarget="none"
      preferredAlignment="right"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button icon={CalendarIcon} onClick={() => setOpen(!open)}>
          {buttonLabel}
        </Button>
      }
      onClose={() => setOpen(false)}
    >
      <Container $mdDown={mdDown}>
        <Presets $mdDown={mdDown}>
          {mdDown ? (
            <Select
              label="dateRangeLabel"
              labelHidden
              onChange={(value) => {
                const range = ranges.find(({ title, alias }) => title === value || alias === value)!
                setSelectedDateRange(range)
                setAllowRangeOnSameDate(false)
              }}
              value={selectedDateRange.title || selectedDateRange.alias || ''}
              options={ranges.map(({ alias, title }) => title || alias)}
            />
          ) : (
            <Scrollable>
              <OptionList
                options={ranges.map((range) => ({
                  value: range.alias,
                  label: range.title,
                }))}
                selected={[selectedDateRange.alias]}
                onChange={(selected) => {
                  const alias = selected[0]
                  const range = ranges.find((range) => range.alias === alias)!
                  setSelectedDateRange(range)
                  setAllowRangeOnSameDate(false)
                }}
              />
            </Scrollable>
          )}
        </Presets>
        <Main>
          <Calendars>
            <BlockStack gap="400">
              <InlineStack gap="200">
                <div style={{ flexGrow: 1, flexBasis: 0 }}>
                  <TextField
                    role="combobox"
                    label={fmt('global.since')}
                    labelHidden
                    prefix={<Icon source={CalendarIcon} />}
                    value={inputValues.since}
                    onChange={handleStartInputValueChange}
                    autoComplete="off"
                  />
                </div>
                <Icon source={ArrowRightIcon} />
                <div style={{ flexGrow: 1, flexBasis: 0 }}>
                  <TextField
                    role="combobox"
                    label={fmt('global.until')}
                    labelHidden
                    prefix={<Icon source={CalendarIcon} />}
                    value={inputValues.until}
                    onChange={handleEndInputValueChange}
                    autoComplete="off"
                  />
                </div>
              </InlineStack>
              <div style={{ flexGrow: '0' }}>
                <DatePicker
                  month={month}
                  year={year}
                  selected={{
                    start: convertUTCDateToLocal(selectedDatePeriod.since),
                    end: convertUTCDateToLocal(dayBeforeSelectedUntil),
                  }}
                  onMonthChange={handleMonthChange}
                  onChange={({ start, end }) =>
                    handleCalendarChange(convertLocalDateToUTC(start), convertLocalDateToUTC(end))
                  }
                  multiMonth={shouldShowMultiMonth}
                  allowRange={
                    selectedDatePeriod.since.getTime() !== dayBeforeSelectedUntil.getTime() || allowRangeOnSameDate
                  }
                />
              </div>
            </BlockStack>
          </Calendars>
          <Buttons>
            <InlineStack align="end" gap="300">
              <Button onClick={cancel}>{fmt('global.cancel')}</Button>
              <Button onClick={apply} variant="primary">
                {fmt('global.apply')}
              </Button>
            </InlineStack>
          </Buttons>
        </Main>
      </Container>
    </Popover>
  )
}

const Container = styled.div<{ $mdDown: boolean }>`
  display: flex;
  align-items: stretch;
  ${(p) =>
    p.$mdDown &&
    css`
      flex-flow: column;
    `}
`

const Presets = styled.div<{ $mdDown: boolean }>`
  ${(p) =>
    !p.$mdDown &&
    css`
      min-height: 100%;
      height: auto;
      max-width: 212px;
      width: 212px;
      border-right: 1px solid var(--p-color-border-secondary);
      position: relative;

      .Polaris-Scrollable {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        max-height: 100%;
      }
    `}
  ${(p) =>
    p.$mdDown &&
    css`
      padding: var(--p-space-500);
      padding-bottom: 0;
    `}
`

const Main = styled.div`
  padding: var(--p-space-500);
  max-width: 516px;
  display: flex;
  flex-flow: column;
  gap: var(--p-space-500);
`

const Calendars = styled.div`
  flex: 1 0 auto;
`

const Buttons = styled.div`
  flex: 0 0 auto;
  border-top: 1px solid var(--p-color-border-secondary);
  margin: calc(var(--p-space-500) * -1);
  margin-top: 0;
  padding: var(--p-space-500);
`
