import { Tooltip } from '@shopify/polaris'
import { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectShopReasonsById } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { useDebouncedState } from 'common/useDebouncedState'
import { DEFAULT_EASING } from 'onboarding/onboarding-styles'
import { notEmpty, openExternalUrl } from 'utils'

import { DataRow } from './DataRow'
import { LineChart, Segment, SegmentsPlaceholder } from './LineChart'
import { ReferenceColor } from './ReferenceColor'

type ItemReasonChartProps = {
  itemName: string
  count: number
  fullChartCount: number
  reasons: (
    | {
        reasonId?: string | null
        count?: number | null
      }
    | null
    | undefined
  )[]
  url?: string
  highlightedId: string | null
  setHighlightedId: (value: React.SetStateAction<string | null>) => unknown
}

export const ItemReasonsChart: FC<ItemReasonChartProps> = ({
  itemName,
  count,
  fullChartCount,
  reasons: _reasons,
  url,
  highlightedId,
  setHighlightedId,
}) => {
  const shopReasonsById = _(selectShopReasonsById)
  const reasons = useMemo(
    () =>
      _reasons
        .filter(notEmpty)
        .filter((r) => r.reasonId && !isNaN(parseFloat(r.count as any)))
        .map((r, i, all) => ({
          ...r,
          beforeCount: all.slice(0, i).reduce((result, r) => result + r.count!, 0),
          reasonName: shopReasonsById?.[+r.reasonId!]?.reason,
          paletteIndex: shopReasonsById?.[+r.reasonId!]?.paletteIndex ?? -1,
        })),
    [_reasons, shopReasonsById],
  )
  const [hovering, setHovering] = useDebouncedState<string | null>(null)
  return (
    <Container>
      <Link
        href={url}
        onClick={(evt) => {
          if (url && url.includes('admin.shopify.com')) {
            evt.preventDefault()
            openExternalUrl(url)
          }
        }}
      >
        <LineChart>
          <SegmentsPlaceholder />
          {reasons.map((r) => (
            <div key={r.reasonId}>
              <TooltipWrapper start={r.beforeCount / fullChartCount} end={(r.beforeCount + r.count!) / fullChartCount}>
                {r.reasonId === hovering && (
                  <Tooltip
                    active
                    width="wide"
                    content={
                      <ReferenceWrapper>
                        <DataRow
                          index={r.paletteIndex}
                          name={r.reasonName}
                          value={
                            <FormattedMessage
                              id="mostReturned.countUnits"
                              defaultMessage="{count} units"
                              values={{ count: r.count! }}
                            />
                          }
                        />
                      </ReferenceWrapper>
                    }
                    dismissOnMouseOut={false}
                  >
                    <TooltipAnchor />
                  </Tooltip>
                )}
              </TooltipWrapper>
              <Segment
                key={r.reasonId}
                start={r.beforeCount / fullChartCount}
                end={(r.beforeCount + r.count!) / fullChartCount}
                $highlighted={r.reasonId === highlightedId}
                onMouseEnter={() => {
                  setHovering(r.reasonId!)
                  setHighlightedId(r.reasonId!)
                }}
                onMouseLeave={() => {
                  setHovering((current) => (current === r.reasonId! ? null : current), true)
                  setHighlightedId((current) => (current === r.reasonId! ? null : current))
                }}
              >
                <ReferenceColor index={r.paletteIndex} />
              </Segment>
            </div>
          ))}
        </LineChart>
        <Texts>
          <Name>{itemName}</Name>
          <Value>
            <FormattedMessage id="mostReturned.countReturned" defaultMessage="{count} returned" values={{ count }} />
          </Value>
        </Texts>
      </Link>
    </Container>
  )
}

const Container = styled.li`
  margin: 0 calc(var(--p-space-400) * -1 + 1px);
`

const Link = styled.a<{ href?: string; $highlight?: boolean }>`
  padding: 6px calc(var(--p-space-400) - 1px);
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  transition: background-color 0.1s ${DEFAULT_EASING};
  ${(p) =>
    p.href &&
    css`
      cursor: pointer;
    `};
  &:focus-visible {
    outline: 0;
  }
  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
  ${(p) =>
    p.$highlight === undefined
      ? css`
          &:hover,
          &:focus-visible {
            background: var(--p-color-bg-surface-hover);
          }
        `
      : p.$highlight &&
        css`
          background: var(--p-color-bg-surface-hover);
        `}
`

const Texts = styled.div`
  display: flex;
  align-items: flex-start;
`

const Name = styled.span`
  flex: 1 1 0;
  display: block;
  margin-right: var(--p-space-300);
`

const Value = styled.span`
  display: block;
  flex: 0 0 auto;
  color: var(--p-color-text-secondary);
`

const TooltipWrapper = styled.div<{ start: number; end: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  left: ${(p) => p.start * 100}%;
  right: ${(p) => (1 - p.end) * 100}%;
`

const TooltipAnchor = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
`

const ReferenceWrapper = styled.div`
  padding: 0 var(--p-space-200);
`
