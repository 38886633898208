import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { selectFormatter, selectShop, selectShopHasLabelIntegration } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

export const NoShippingAccountBanner: FC = () => {
  const fmt = _(selectFormatter)
  const navigate = useNavigate()

  const shop = _(selectShop)
  const hasLabelIntegration = _(selectShopHasLabelIntegration)

  const missingIntegration = !hasLabelIntegration && shop.shipFree

  const [dismissed, setDismissed] = useState(
    sessionStorage.getItem('rz-no-shipping-account-banner-dismissed') === 'true',
  )

  if (!missingIntegration || dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.noShippingAccountTitle')}
      tone="warning"
      action={{
        content: fmt('banners.noShippingAccountButton'),
        onAction: () => navigate('/config-settings/logistics-integrations#easypost'),
      }}
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-no-shipping-account-banner-dismissed', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.noShippingAccountMessage"
          defaultMessage="You have enabled pre-paid shipping labels but have not yet connected a shipping account integration. Prepaid labels will not be available to your customers until a shipping method is connected."
        />
      </p>
    </Banner>
  )
}
