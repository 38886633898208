import { FC, PropsWithChildren } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { selectLocale, selectMessages } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

export const IntlProvider: FC<PropsWithChildren> = ({ children }) => {
  const locale = _(selectLocale)
  const messages = _(selectMessages)
  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  )
}
