import { ReportCallback, onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: ReportCallback): void => {
  if (onPerfEntry != null && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry)
    onFCP(onPerfEntry)
    onFID(onPerfEntry)
    onINP(onPerfEntry)
    onLCP(onPerfEntry)
    onTTFB(onPerfEntry)
  }
}

export default reportWebVitals
