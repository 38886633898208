import { Cache, offlineExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import { v4 as uuidv4 } from 'uuid'

import { INDEXED_DB_NAME } from 'config'

import {
  MutationUpdateReturnArgs,
  PackageCreateMutation,
  PackageDeleteMutation,
  PackagesDocument,
  PackagesQuery,
  ReturnTransitionStageMutation,
  ReturnUpdateStatusMutation,
  WarehouseCreateMutation,
  WarehouseDeleteMutation,
  WarehousesDocument,
  WarehousesQuery,
} from './gql'

const storage = makeDefaultStorage({ idbName: INDEXED_DB_NAME, maxAge: 7 })

const invalidate = (cache: Cache, typename: string, ids: number[]) => {
  ids.forEach((id) => {
    cache.invalidate({ __typename: typename, id })
  })
}

const cache = offlineExchange({
  storage,
  keys: {
    ActiveReturnsMetricType: () => uuidv4(),
    ActiveReturnStageMetricType: () => uuidv4(),
    ActionTakenMetricType: () => uuidv4(),
    DashboardMetrics: () => uuidv4(),
    // @ts-expect-error: uuid is always a string
    DynamicTranslation: (obj) => obj.uuid,
    EasyPostCarrierConfig: () => uuidv4(),
    PolicyMetricType: () => uuidv4(),
    ReturnProductDetailMetricType: () => uuidv4(),
    ReturnProductMetricType: () => uuidv4(),
    ReturnsMetricType: () => uuidv4(),
    ProductReasonMetricType: () => uuidv4(),
    StageMetricType: () => uuidv4(),
    AdminOrderLookupResponse: () => uuidv4(),
    Shipment: () => uuidv4(),
    SuggestionRefundType: () => uuidv4(),
    SuggestedRefundType: () => uuidv4(),
    Money: () => uuidv4(),
    MoneySet: () => uuidv4(),
  },
  updates: {
    Mutation: {
      updateReturn(result, args: MutationUpdateReturnArgs, cache) {
        const returnId = args.returnId
        invalidate(cache, 'Return', [returnId])
      },
      returnsDelete(result, args, cache) {
        const stringIds = args.returnIds as Array<string>
        const returnIds = stringIds.map((id) => parseInt(id, 10))
        invalidate(cache, 'DashboardReturnResult', returnIds)
      },
      returnUpdateStatus(result: ReturnUpdateStatusMutation, arg, cache) {
        if (result.returnUpdateStatus) {
          const affectedIds = result.returnUpdateStatus.affectedReturnIds as Array<number>
          invalidate(cache, 'DashboardReturnResult', affectedIds)
        }
      },
      returnTransitionStage(result: ReturnTransitionStageMutation, arg, cache) {
        if (result.returnTransitionStage) {
          const affectedIds = result.returnTransitionStage.affectedReturnIds as Array<number>
          invalidate(cache, 'DashboardReturnResult', affectedIds)
        }
      },
      warehouseCreate(result: WarehouseCreateMutation, args, cache) {
        if (result.warehouseCreate?.warehouse)
          cache.updateQuery<WarehousesQuery>(
            { query: WarehousesDocument },
            (data) =>
              data && {
                ...data,
                warehouses: data.warehouses && [...data.warehouses, result.warehouseCreate!.warehouse!],
              },
          )
      },
      warehouseDelete(result: WarehouseDeleteMutation, args, cache) {
        if (!result.warehouseDelete?.error)
          cache.updateQuery<WarehousesQuery>(
            { query: WarehousesDocument },
            (data) => data && { ...data, warehouses: (data.warehouses || []).filter((w) => w?.id !== args.id) },
          )
      },
      packageCreate(result: PackageCreateMutation, args, cache) {
        if (result.packageCreate?.package)
          cache.updateQuery<PackagesQuery>(
            { query: PackagesDocument },
            (data) =>
              data && {
                ...data,
                packages: data.packages && [...data.packages, result.packageCreate!.package!],
              },
          )
      },
      packageDelete(result: PackageDeleteMutation, args, cache) {
        if (!result.packageDelete?.error)
          cache.updateQuery<PackagesQuery>(
            { query: PackagesDocument },
            (data) => data && { ...data, packages: (data.packages || []).filter((p) => p?.id !== args.id) },
          )
      },
    },
  },
})

export default cache
