import { FC, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

export const ReferencesPlaceholder: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>{children || <FormattedMessage id="dashboard.nothingFound" defaultMessage="Nothing found" />}</Container>
  )
}

const Container = styled.div`
  text-align: center;
  width: 100%;
  opacity: 0.4;
`
