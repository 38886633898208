import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Mode, RMA } from 'rma/models'
import { selectShopSystemStages } from 'store/global/global.selectors'
import { AppThunk, loadSlice } from 'store/store'

import { ExchangeOrderStatus, ReturnType, SystemStage, WarehousesQuery } from 'gql'

import {
  selectCreditEnabled,
  selectExchangeEnabled,
  selectQuickHighlightExchangeGroup,
  selectQuickHighlightGroup,
  selectRefundEnabled,
  selectRMAItems,
  selectRMAStage,
} from './rma.selectors'

type Warehouses = Exclude<WarehousesQuery['warehouses'], null | undefined>
export type Warehouse = Exclude<Warehouses[number], null | undefined>

export interface RMASlice {
  mode: Mode
  stageUpdatingTo: string | null
  selectionEnabled: boolean
  selected: string[]
  showTestReturnBanner: boolean
  showDeleteModal: boolean
  gallery: {
    id: string | null
    index: number
  }
  rma?: RMA
  quickHighlightGroup: string | null
  quickHighlightExchangeGroup: string | null
}

const initialState: RMASlice = {
  mode: 'resting',
  stageUpdatingTo: null,
  selectionEnabled: false,
  selected: [],
  showTestReturnBanner: true,
  showDeleteModal: false,
  gallery: {
    id: null,
    index: 0,
  },
  rma: undefined,
  quickHighlightGroup: null,
  quickHighlightExchangeGroup: null,
}

const rmaSlice = createSlice({
  name: 'rma',
  initialState,
  reducers: {
    resetRMAState(state: RMASlice) {
      state.mode = initialState.mode
      state.stageUpdatingTo = initialState.stageUpdatingTo
      state.showTestReturnBanner = initialState.showTestReturnBanner
      state.showDeleteModal = initialState.showDeleteModal
      state.selectionEnabled = initialState.selectionEnabled
      state.selected = initialState.selected
      state.gallery = initialState.gallery
      state.rma = undefined
    },
    handleData(state: RMASlice, { payload: { rma } }: PayloadAction<{ rma: RMASlice['rma'] }>) {
      state.rma = rma
      if (state.mode === 'exchange') {
        const completedExchanges = rma?.items
          .filter((i) => i.exchangeOrder?.status === ExchangeOrderStatus.Completed)
          .map((i) => i.id)
        if (completedExchanges) state.selected = state.selected.filter((id) => !completedExchanges.includes(id))
      }
    },
    dismissBanner(state: RMASlice) {
      state.showTestReturnBanner = false
    },
    openDeleteModal(state: RMASlice) {
      state.showDeleteModal = true
    },
    closeDeleteModal(state: RMASlice) {
      state.showDeleteModal = false
    },
    setMode(state: RMASlice, { payload: mode }: PayloadAction<RMASlice['mode']>) {
      state.mode = mode
    },
    setStageUpdatingTo(state: RMASlice, { payload: stageId }: PayloadAction<string>) {
      state.stageUpdatingTo = stageId
    },
    setSelectionEnabled(state: RMASlice, { payload: enabled }: PayloadAction<boolean>) {
      state.selectionEnabled = enabled
    },
    selectAllItems(state: RMASlice) {
      state.selected = state.rma?.items.map((i) => i.id) || []
    },
    clearSelection(state: RMASlice) {
      state.selected = []
    },
    selectExchangeModeItems(state: RMASlice) {
      state.selected =
        state.rma?.items
          .filter(
            (item) =>
              !item.isMissing &&
              !item.isExpired &&
              item.exchangeOrder &&
              item.exchangeOrder.status !== ExchangeOrderStatus.Completed &&
              !item.isResolvedManually &&
              !(!item.exchangeOrder.consolidatedOrderExchange && item.returnType !== ReturnType.Exchange),
          )
          .map((i) => i.id) || []
    },
    selectRestockable(state: RMASlice) {
      state.selected = state.rma?.items.filter((i) => !i.isMissing && !i.isRestocked).map((i) => i.id) || []
    },
    toggleItemsSelected(state: RMASlice, { payload: ids }: PayloadAction<string[]>) {
      const selectedCount = ids.filter((id) => state.selected.includes(id)).length
      if (selectedCount < ids.length) {
        for (const id of ids) if (!state.selected.includes(id)) state.selected.push(id)
      } else {
        for (const id of ids) if (state.selected.includes(id)) state.selected.splice(state.selected.indexOf(id), 1)
      }
    },
    setItemsSelected(
      state: RMASlice,
      { payload: { ids, selected } }: PayloadAction<{ ids: string[]; selected: boolean }>,
    ) {
      if (selected) {
        for (const id of ids) if (!state.selected.includes(id)) state.selected.push(id)
      } else {
        for (const id of ids) if (state.selected.includes(id)) state.selected.splice(state.selected.indexOf(id), 1)
      }
    },
    setItemsSelectedIds(state: RMASlice, { payload: ids }: PayloadAction<string[]>) {
      state.selected = ids
    },
    openGallery(state: RMASlice, { payload: { id, startIndex } }: PayloadAction<{ id: string; startIndex?: number }>) {
      state.gallery.id = id
      state.gallery.index = startIndex || 0
    },
    galleryNext(state: RMASlice) {
      const images = state.rma?.items.find((i) => i.id === state.gallery.id)?.images
      if (images) state.gallery.index = (state.gallery.index + 1) % images.length
    },
    galleryPrevious(state: RMASlice) {
      const images = state.rma?.items.find((i) => i.id === state.gallery.id)?.images
      if (images) state.gallery.index = (state.gallery.index - 1 + images.length) % images.length
    },
    closeGallery(state: RMASlice) {
      state.gallery.id = null
    },
    setQuickHighlightGroup(state: RMASlice, { payload: newValue }: PayloadAction<string | null>) {
      state.quickHighlightGroup = newValue
    },
    setQuickHighlightExchangeGroup(state: RMASlice, { payload: newValue }: PayloadAction<string | null>) {
      state.quickHighlightExchangeGroup = newValue
    },
    clearRMASlice(state: RMASlice) {
      Object.assign(state, initialState)
    },
  },
})

export const {
  resetRMAState,
  handleData,
  dismissBanner,
  openDeleteModal,
  closeDeleteModal,
  setMode,
  setStageUpdatingTo,
  setSelectionEnabled,
  selectAllItems,
  clearSelection,
  selectExchangeModeItems,
  selectRestockable,
  openGallery,
  galleryNext,
  galleryPrevious,
  closeGallery,
  toggleItemsSelected,
  setItemsSelected,
  setItemsSelectedIds,
  setQuickHighlightGroup,
  setQuickHighlightExchangeGroup,
  clearRMASlice,
} = rmaSlice.actions

export const updateMode =
  (mode: RMASlice['mode']): AppThunk =>
  (dispatch) => {
    dispatch(setMode(mode))

    if (mode === 'refund') dispatch(selectRefundable)
    if (mode === 'exchange') dispatch(selectExchangeModeItems())
    if (mode === 'credit') dispatch(selectCreditable)
    if (mode === 'resolve') dispatch(selectManuallyResolvable)
    if (mode === 'restock') dispatch(selectRestockable())

    dispatch(
      setSelectionEnabled(
        mode === 'refund' || mode === 'exchange' || mode === 'credit' || mode == 'resolve' || mode === 'restock',
      ),
    )
  }

export const updateToStage =
  (stageId: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState()

    const currentStage = selectRMAStage(state)
    const currentSystemStage = currentStage?.systemStage
    const currentStageIsPreReceived = !!currentSystemStage && currentSystemStage !== SystemStage.Received

    const systemStages = selectShopSystemStages(state)
    const systemStage = systemStages?.find((ss) => ss.id === stageId)?.systemStage
    const systemStageIsPostReceived = !systemStage || systemStage === SystemStage.Received

    const enableSelection =
      systemStage === SystemStage.Received || (currentStageIsPreReceived && systemStageIsPostReceived)

    dispatch(updateMode('update-to-stage'))
    dispatch(setStageUpdatingTo(stageId))
    dispatch(setSelectionEnabled(enableSelection))
    if (enableSelection) dispatch(selectAllItems())
  }

let quickHighlightTimeout: number | undefined

export const quicklyHighlightGroup =
  (groupId: string): AppThunk =>
  (dispatch, getState) => {
    clearTimeout(quickHighlightTimeout)
    dispatch(setQuickHighlightGroup(null))
    quickHighlightTimeout = window.setTimeout(() => {
      dispatch(setQuickHighlightGroup(groupId))
      quickHighlightTimeout = window.setTimeout(() => {
        if (selectQuickHighlightGroup(getState()) === groupId) dispatch(setQuickHighlightGroup(null))
      }, 2500)
    })
  }

let quickExchangeHighlightTimeout: number | undefined

export const quicklyHighlightExchangeGroup =
  (exchangeGroupId: string): AppThunk =>
  (dispatch, getState) => {
    clearTimeout(quickExchangeHighlightTimeout)
    dispatch(setQuickHighlightExchangeGroup(null))
    quickExchangeHighlightTimeout = window.setTimeout(() => {
      dispatch(setQuickHighlightExchangeGroup(exchangeGroupId))
      quickExchangeHighlightTimeout = window.setTimeout(() => {
        if (selectQuickHighlightExchangeGroup(getState()) === exchangeGroupId)
          dispatch(setQuickHighlightExchangeGroup(null))
      }, 2500)
    })
  }

export const selectRefundable: AppThunk = (dispatch, getState) => {
  const items = selectRMAItems(getState())
  dispatch(
    setItemsSelectedIds(
      items
        ?.filter((i) => !i.isMissing && !i.isExpired && !i.resolvedAt && i.returnType === ReturnType.Refund)
        .map((i) => i.id) || [],
    ),
  )
}

export const selectCreditable: AppThunk = (dispatch, getState) => {
  const items = selectRMAItems(getState())
  dispatch(
    setItemsSelectedIds(
      items
        ?.filter((i) => !i.isMissing && !i.isExpired && !i.resolvedAt && i.returnType === ReturnType.Credit)
        .map((i) => i.id) || [],
    ),
  )
}

export const selectManuallyResolvable: AppThunk = (dispatch, getState) => {
  const state = getState()

  const items = selectRMAItems(state)
  const refundEnabled = selectRefundEnabled(state)
  const exchangeEnabled = selectExchangeEnabled(state)
  const creditEnabled = selectCreditEnabled(state)

  dispatch(
    setItemsSelectedIds(
      items
        ?.filter(
          (i) =>
            !i.isMissing &&
            !i.isExpired &&
            !i.resolvedAt &&
            ((i.returnType === ReturnType.Refund && !refundEnabled) ||
              (i.returnType === ReturnType.Exchange && !exchangeEnabled) ||
              (i.returnType === ReturnType.Credit && !creditEnabled)),
        )
        .map((i) => i.id) || [],
    ),
  )
}

loadSlice(rmaSlice)
