import { Spinner } from '@shopify/polaris'
import { FC, lazy, Suspense } from 'react'
import { _ } from 'store/hooks'
import { selectOnboardingOpen } from 'store/onboarding/onboarding.selectors'
import styled from 'styled-components'

import { useCommonData } from 'common/useCommonData'
import { useOnboardingQuery } from 'gql'

const Onboarding = lazy(() => import('onboarding/Onboarding'))

export const OnboardingLoader: FC = () => {
  const onboardingOpen = _(selectOnboardingOpen)
  useCommonData(useOnboardingQuery, { pause: !onboardingOpen }, 'onboarding')
  if (!onboardingOpen) return <></>
  return (
    <Container>
      <Suspense fallback={<Spinner />}>
        <Onboarding />
      </Suspense>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--p-background);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`
