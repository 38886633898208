import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter, selectShop } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import ExternalButton from 'common/ExternalButton'

export const MissingPermissionsBanner: FC = () => {
  const fmt = _(selectFormatter)
  const shop = _(selectShop)

  const missingScopes = shop.missingScopes

  const [dismissed, setDismissed] = useState(
    sessionStorage.getItem('rz-missing-permission-banner-dismissed') === 'true',
  )

  if (!missingScopes?.length || dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.missingPermissionTitle')}
      tone="warning"
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-missing-permission-banner-dismissed', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.missingPermissionMessage"
          defaultMessage={
            'You have features enabled within ReturnZap that require additional data access permissions from Shopify. Please authorize the new access permissions through Shopify. Certain features within ReturnZap will not function until access is updated. <Link>Learn more about Shopify app permissions.</Link> <br></br><br></br> Missing permissions are: {missingScopes}'
          }
          values={{
            br: () => <br />,
            missingScopes: missingScopes.join(', '),
            Link: (content) => (
              <a
                href="https://help.shopify.com/en/manual/apps/app-personal-information"
                target="_blank"
                rel="noreferrer"
              >
                {content}
              </a>
            ),
          }}
        />
      </p>
      <br />
      <ExternalButton url={shop.reauthenticateUrl || ''} disabled={!shop.reauthenticateUrl}>
        {fmt('banners.missingPermissionAuthButton')}
      </ExternalButton>
    </Banner>
  )
}
