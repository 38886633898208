import { BadgeProgressValue, BadgeStatusValue as ToneValue } from '@shopify/polaris'

import { ResolvedStatus, ReturnType, SystemStage } from 'gql'

// Break points

export const TWO_COLUMNS_BREAKPOINT = 880
export const VERTICAL_ITEMS_BREAKPOINT = 600

// Sidebar

export const SIDEBAR_WIDTH = '310px'
export const SIDEBAR_FULL_WIDTH_BREAKPOINT = '400px'

// Stage Badges

type StageBadge = {
  id: string
  tone?: ToneValue
  progress: BadgeProgressValue
}

export const STAGE_BADGES: StageBadge[] = [
  {
    id: SystemStage.PendingApproval,
    tone: ToneValue.Attention,
    progress: BadgeProgressValue.Incomplete,
  },
  {
    id: SystemStage.Rejected,
    progress: BadgeProgressValue.Complete,
  },
  { id: SystemStage.Approved, progress: BadgeProgressValue.PartiallyComplete },
  {
    id: SystemStage.AutomaticallyApproved,
    progress: BadgeProgressValue.PartiallyComplete,
  },
  { id: SystemStage.Received, progress: BadgeProgressValue.Complete },
]

export const STAGE_BADGES_BY_SS = STAGE_BADGES.reduce(
  (result, stage) => ({ ...result, [stage.id]: stage }),
  {} as Record<SystemStage, StageBadge | undefined>,
)

// Resolved state

type ResolvedStatusBadge = {
  id: ResolvedStatus
  tone?: ToneValue
  progress: BadgeProgressValue
}

export const RESOLVED_BADGES: ResolvedStatusBadge[] = [
  { id: ResolvedStatus.Unresolved, tone: ToneValue.Attention, progress: BadgeProgressValue.Incomplete },
  {
    id: ResolvedStatus.PartiallyResolved,
    tone: ToneValue.Attention,
    progress: BadgeProgressValue.PartiallyComplete,
  },
  { id: ResolvedStatus.Resolved, progress: BadgeProgressValue.Complete },
]

export const RESOLVED_BADGES_BY_ID = RESOLVED_BADGES.reduce(
  (result, state) => ({ ...result, [state.id]: state }),
  {} as Record<ResolvedStatus, ResolvedStatusBadge | undefined>,
)

// Policy

export const RETURN_TYPES: ReturnType[] = [ReturnType.Refund, ReturnType.Exchange, ReturnType.Credit]

// Timeline

export const AVATAR_SIZE = 40
