import { Button, ButtonGroup, Card, Tabs, useBreakpoints } from '@shopify/polaris'
import { ChevronDownIcon } from '@shopify/polaris-icons'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { DashboardQuery } from 'gql'
import { notEmpty } from 'utils'

import { ChartName } from './ChartName'
import { DataRows } from './DataRows'
import { ItemReasonsChart } from './ItemReasonsChart'
import { ReferencesPlaceholder } from './ReferencesPlaceholder'
import { RightAlignedTabs } from './RightAlignedTabs'

type MostReturnedSlice = Exclude<
  Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['mostReturned'],
  undefined | null
>
type MostReturnedProps = { mostReturnedData: MostReturnedSlice }

export const MostReturned: FC<MostReturnedProps> = ({ mostReturnedData }) => {
  const { lgDown, mdDown } = useBreakpoints()
  const fmt = _(selectFormatter)

  const [expanded, setExpanded] = useState(false)
  const [selected, setSelected] = useState<'products' | 'variants'>('products')

  const rows = mostReturnedData[selected]!.filter(notEmpty).slice(0, expanded ? undefined : 5)
  const showExpandButton = mostReturnedData[selected]!.length > 5

  const [highlightedId, setHighlightedId] = useState<string | null>(null)

  const fullChartCount = Math.max(...rows.map((r) => r.count!))

  const tabs = [
    {
      id: 'products' as const,
      content: fmt('global.products'),
    },
    {
      id: 'variants' as const,
      content: fmt('global.variants'),
    },
  ]

  return (
    <Container columnSpan={mdDown ? 6 : lgDown ? 3 : 4}>
      <Card>
        <Header>
          <ChartName>
            <FormattedMessage id="mostReturned.title" defaultMessage="Most returned" />
          </ChartName>
          <Options $gone={!rows.length}>
            <RightAlignedTabs>
              <Tabs
                tabs={tabs}
                selected={tabs.indexOf(tabs.find((t) => t.id === selected)!)}
                onSelect={(index) => setSelected(tabs[index].id)}
              />
            </RightAlignedTabs>
          </Options>
        </Header>
        {rows.length ? (
          <DataRows>
            {rows.map((r) => (
              <ItemReasonsChart
                key={r.name}
                itemName={r.name!}
                count={r.count!}
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                reasons={r.reasons!}
                fullChartCount={fullChartCount}
                url={r.url ?? undefined}
                highlightedId={highlightedId}
                setHighlightedId={setHighlightedId}
              />
            ))}
            {showExpandButton && (
              <More $expanded={expanded} onClick={() => setExpanded((current) => !current)}>
                <ChevronDownIcon />
              </More>
            )}
          </DataRows>
        ) : (
          <PlaceholderContainer>
            <ReferencesPlaceholder />
          </PlaceholderContainer>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div<{ columnSpan: number }>`
  grid-column: span ${(p) => p.columnSpan};
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(var(--p-space-300) * -1);
`

const Options = styled.div<{ $gone: boolean }>`
  ${(p) =>
    p.$gone &&
    css`
      visibility: hidden;
    `}
`

const More = styled.button<{ $expanded: boolean }>`
  position: absolute;
  inset: 0;
  top: auto;
  padding: 0;
  border: 0;
  line-height: 1;
  background: transparent;
  cursor: pointer;
  color: #999999;
  &:hover,
  &:focus-visible {
    color: var(--p-color-text);
    background: var(--p-color-bg-surface-hover);
    opacity: 1;
  }
  svg {
    fill: currentColor;
    position: relative;
    top: 0;
    width: 14px;
    ${(p) =>
      p.$expanded &&
      css`
        transform: scaleY(-1);
      `}
  }
`

const PlaceholderContainer = styled.div`
  margin-top: 12px;
  border-top: 1px solid var(--p-color-border-secondary);
  padding: 11px 0 0;
  margin-bottom: -4px;
`
