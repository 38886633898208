import { AppProvider } from '@shopify/polaris'
import deTranslations from '@shopify/polaris/locales/de.json'
import enTranslations from '@shopify/polaris/locales/en.json'
import frTranslations from '@shopify/polaris/locales/fr.json'
import { ComponentProps, FC, PropsWithChildren } from 'react'
import { selectLocale } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { LocaleId } from 'intl/intl'

type Dictionary = ComponentProps<typeof AppProvider>['i18n']

const POLARIS_LOCALES: Record<LocaleId, Dictionary> = {
  'en': enTranslations,
  'en-us': enTranslations,
  'fr': frTranslations,
  'de': deTranslations,
}

const PolarisProvider: FC<PropsWithChildren> = ({ children }) => {
  const locale = _(selectLocale)
  const translations = Object.keys(POLARIS_LOCALES).includes(locale) ? POLARIS_LOCALES[locale] : enTranslations
  return <AppProvider i18n={translations}>{children}</AppProvider>
}

export default PolarisProvider
