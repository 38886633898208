import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useRulesQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

const RulesPage = lazy(() => import('./RulesPage'))

export const RulesPageLoader: FC = () => {
  const [{ data, fetching, stale }, executeRulesQuery] = useCommonData(useRulesQuery, 'rules')
  const loaded = _(selectDataLoaded('rules'))
  return (
    <>
      {(fetching || stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <RulesPage rules={data?.returnRules} reload={() => executeRulesQuery({ requestPolicy: 'network-only' })} />
      </PageRevealer>
    </>
  )
}
