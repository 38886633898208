export const tagClarity = (key: string, value: string) => {
  // @ts-expect-error: point is to test for variable
  if (typeof clarity !== 'undefined') {
    // @ts-expect-error: it's here, I just checked
    clarity('set', key, value)
  }
}

export const identifyClarity = (shop: string, user: string) => {
  // @ts-expect-error: point is to test for variable
  if (typeof clarity !== 'undefined') {
    // @ts-expect-error: it's here, I just checked
    clarity('identify', shop, user)
  }
}
