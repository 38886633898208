import { Toast } from '@shopify/polaris'
import { FC } from 'react'
import { selectGlobalToastMessage } from 'store/global/global.selectors'
import { showToast } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'

export const GlobalToasts: FC = () => {
  const dispatch = useAppDispatch()
  const toastMessage = _(selectGlobalToastMessage)
  return (
    <>
      {toastMessage && <Toast content={toastMessage} onDismiss={() => dispatch(showToast(''))} duration={1000 * 2} />}
    </>
  )
}
