import { Layout } from '@shopify/polaris'
import { ComponentProps, FC } from 'react'
import styled from 'styled-components'

/*
 * This component fixes an issue where Layout.AnnotatedSections will render without padding
 * in small viewports in Polaris v12. ¯\_(ツ)_/¯
 */

export const AnnotatedSection: FC<ComponentProps<typeof Layout.AnnotatedSection>> = (props) => {
  return (
    <Styler>
      <Layout.AnnotatedSection {...props} />
    </Styler>
  )
}

const Styler = styled.div`
  min-width: 100%;
  max-width: 100%;
  & + & .Polaris-Layout__AnnotatedSection {
    border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
    padding-top: var(--p-space-400);
  }
  .Polaris-Layout__Annotation {
    margin-left: var(--p-space-800);
    @media (min-width: 490px) {
      margin-left: var(--p-space-400);
    }
  }
`
