import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { selectFormatter, selectShop } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

export const NoPackageSizeBanner: FC = () => {
  const fmt = _(selectFormatter)
  const navigate = useNavigate()

  const shop = _(selectShop)

  const missingPackages = (shop.shipFree || shop.shipPay) && !shop.packages.length

  const [dismissed, setDismissed] = useState(sessionStorage.getItem('rz-no-package-size-banner-dismissed') === 'true')

  if (!missingPackages || dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.noPackageSizeTitle')}
      tone="warning"
      action={{
        content: fmt('banners.noPackageSizeButton'),
        onAction: () => navigate('/config-settings/shipping#packages'),
      }}
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-no-package-size-banner-dismissed', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.noPackageSizeMessage"
          defaultMessage="You do not have any package sizes configured. Package dimensions are required by some carriers. Adding package dimensions increases the accuracy of the shipping rates you receive and prevents overages and fees."
        />
      </p>
    </Banner>
  )
}
