import { useEffect, useState } from 'react'

import { LOCALSTORAGE_IS_AVALAILABLE } from 'config'

export function useStickyState<Type>(key: string, defaultValue: Type): [Type, (value: Type) => void] {
  const [value, setValue] = useState<Type>(() => {
    const stickyValue = LOCALSTORAGE_IS_AVALAILABLE ? window.localStorage.getItem(key) : null
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  useEffect(() => {
    if (LOCALSTORAGE_IS_AVALAILABLE) window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}
