import { AppBridgeState, ClientApplication, createApp } from '@shopify/app-bridge'

import { getToken } from 'auth'
import { LOCALSTORAGE_IS_AVALAILABLE, SHOPIFY_API_KEY } from 'config'

const getURLParam = (key: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(key)
}

export const getShopifyVal = (key: string) => {
  const urlVal = getURLParam(key)
  let lsVal
  if (LOCALSTORAGE_IS_AVALAILABLE) {
    lsVal = localStorage.getItem(key)
    if (urlVal) {
      // Always keep localstorage up to date
      if (urlVal !== lsVal) {
        localStorage.setItem(key, urlVal)
      }
      return urlVal
    }
  }
  const retVal = lsVal ?? urlVal ?? ''
  return retVal
}

export const getShopifyConfig = () => {
  const host = getShopifyVal('host')
  const config = {
    apiKey: SHOPIFY_API_KEY,
    host,
    forceRedirect: !getToken(),
  }
  return config
}

let app: ClientApplication<AppBridgeState> | null = null

export const getShopifyApp = () => {
  if (!app) {
    const config = getShopifyConfig()
    if (config.host) app = createApp(config)
  }
  return app
}
