import { FC, useMemo } from 'react'
import { selectFormatter, selectShopReasonsById } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { DashboardQuery } from 'gql'
import { notEmpty } from 'utils'

import { DonutChart } from './DonutChart'

type ReasonSlice = Exclude<Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['reason'], undefined | null>
type ReasonChartProps = { data: ReasonSlice; display: 'units' | 'amount' }

export const ReasonChart: FC<ReasonChartProps> = ({ data, display }) => {
  const fmt = _(selectFormatter)
  const shopReasonsById = _(selectShopReasonsById)
  const extendedData = useMemo(
    () =>
      [...data].filter(notEmpty).map((reason) => ({
        name: reason.label || '',
        count: display === 'units' ? reason.count! : parseFloat(reason.amount as string),
        paletteIndex: shopReasonsById?.[+reason.id!]?.paletteIndex ?? -1,
      })),
    [data, display, shopReasonsById],
  )
  return <DonutChart name={fmt('global.returnReason')} data={extendedData} money={display === 'amount'} />
}
