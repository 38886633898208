import styled, { css } from 'styled-components'

export const DASHBOARD_PALETTE = [
  'rgb(22, 166, 121)', // 'var(--p-color-bg-fill-success)'
  'rgb(90, 36, 205)', // 'var(--p-color-text-magic)',
  'rgb(197, 40, 12)', // 'var(--p-color-bg-fill-critical)',
  'rgb(216, 155, 13)', // 'var(--p-color-bg-fill-caution)',
  'rgb(242, 117, 34)', // 'var(--p-color-icon-warning)',
  'rgb(32, 130, 141)', // 'var(--p-color-text-info)',
  'rgb(14, 53, 108)', // 'var(--p-color-bg-fill-brand)',
]

export const ReferenceColor = styled.div<{ index: number; palette?: string[] }>`
  position: absolute;
  inset: 0;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
  ${(p) => css`
    background: ${(p.palette || DASHBOARD_PALETTE)[p.index % (p.palette || DASHBOARD_PALETTE).length]};
  `}
  ${(p) =>
    p.index >= (p.palette || DASHBOARD_PALETTE).length &&
    p.index < (p.palette || DASHBOARD_PALETTE).length * 2 &&
    css`
      &::after {
        background: rgba(0 0 0 / 0.3);
      }
    `}
${(p) =>
    p.index >= (p.palette || DASHBOARD_PALETTE).length * 2 &&
    css`
      &::after {
        background: rgba(255 255 255 / 0.3);
      }
    `}
`
