import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PortalOverlaySlice {
  open: boolean
  mode: 'test' | 'customizer' | 'create-return'
}

const initialState: PortalOverlaySlice = {
  open: false,
  mode: 'test',
}

export const portalOverlaySlice = createSlice({
  name: 'portalOverlay',
  initialState,
  reducers: {
    openPortalOverlay: (state, { payload: mode }: PayloadAction<PortalOverlaySlice['mode'] | undefined>) => {
      state.open = true
      if (mode) state.mode = mode
    },
    closePortalOverlay: (state) => {
      state.open = false
    },
    setPortalOverlayMode: (state, { payload: mode }: PayloadAction<PortalOverlaySlice['mode']>) => {
      state.mode = mode
    },
  },
})

export const { openPortalOverlay, closePortalOverlay, setPortalOverlayMode } = portalOverlaySlice.actions

export default portalOverlaySlice.reducer
