import '@shopify/app-bridge'
import { NavMenu } from '@shopify/app-bridge-react'
import { History } from '@shopify/app-bridge/actions'
import { refreshTraceId } from 'graphQLClient'
import { FC, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { getShopifyApp } from 'utils/shopify'

export const ShopifyNavigation: FC = () => {
  const app = getShopifyApp()
  const location = useLocation()

  // Update Shopify location
  const history = useMemo<History.History | null>(() => app && History.create(app), [app])
  useEffect(() => {
    history?.dispatch(History.Action.PUSH, location.pathname)
  }, [history, location.pathname])

  // Update traceId
  useEffect(() => refreshTraceId(location.pathname), [location.pathname])

  // Render App Bridge navigation
  return (
    <div style={{ display: 'none' }}>
      <NavMenu>
        <Link to="/" rel="home">
          <FormattedMessage id="shopifyNavigation.home" defaultMessage="Home" />
        </Link>
        <Link to="/returns">
          <FormattedMessage id="shopifyNavigation.returns" defaultMessage="Returns" />
        </Link>
        <Link to="/config-settings">
          <FormattedMessage id="shopifyNavigation.settings" defaultMessage="Settings" />
        </Link>
        <Link to="/help-and-support">
          <FormattedMessage id="shopifyNavigation.help" defaultMessage="Help" />
        </Link>
      </NavMenu>
    </div>
  )
}
