import { createSelector } from '@reduxjs/toolkit'
import { selectFormatter } from 'store/global/global.selectors'

import { RuleAction, RuleActionOption, RuleAttribute, RuleOperator, RuleWhen } from 'gql'
import { AttributeType } from 'myconstants'

// const selectRules = (s: RootState) => s.rules

export const selectWhenOptions = createSelector([selectFormatter], (fmt) => ({
  [RuleWhen.And]: {
    label: fmt('ruleWhen.and.description'),
    value: RuleWhen.And,
  },
  [RuleWhen.Or]: {
    label: fmt('ruleWhen.or.description'),
    value: RuleWhen.Or,
  },
}))

export const selectRuleAttributes = createSelector([selectFormatter], (fmt) => ({
  empty: { label: '', value: '' },
  [RuleAttribute.CustomerCountry]: {
    label: fmt('ruleAttributes.customer.Country'),
    value: RuleAttribute.CustomerCountry,
  },
  [RuleAttribute.CustomerEmail]: {
    label: fmt('ruleAttributes.customer.Email'),
    value: RuleAttribute.CustomerEmail,
  },
  [RuleAttribute.CustomerId]: {
    label: fmt('ruleAttributes.customer.ID'),
    value: RuleAttribute.CustomerId,
  },
  [RuleAttribute.CustomerLtv]: {
    label: fmt('ruleAttributes.customer.LifetimeValue'),
    value: RuleAttribute.CustomerLtv,
  },
  [RuleAttribute.CustomerNumOrders]: {
    label: fmt('ruleAttributes.customer.NumberOfOrders'),
    value: RuleAttribute.CustomerNumOrders,
  },
  [RuleAttribute.CustomerTags]: {
    label: fmt('ruleAttributes.customer.Tags'),
    value: RuleAttribute.CustomerTags,
  },
  [RuleAttribute.OrderDate]: {
    label: fmt('ruleAttributes.order.Date'),
    value: RuleAttribute.OrderDate,
  },
  [RuleAttribute.OrderDaysSinceOrdered]: {
    label: fmt('ruleAttributes.order.OrderDaysSinceOrdered'),
    value: RuleAttribute.OrderDaysSinceOrdered,
  },
  [RuleAttribute.LineItemDaysSinceDeliveredAt]: {
    label: fmt('ruleAttributes.order.LineItemDaysSinceDeliveredAt'),
    value: RuleAttribute.LineItemDaysSinceDeliveredAt,
  },
  [RuleAttribute.DiscountCode]: {
    label: fmt('ruleAttributes.order.DiscountCode'),
    value: RuleAttribute.DiscountCode,
  },
  [RuleAttribute.OrderDiscountPercent]: {
    label: fmt('ruleAttributes.order.DiscountPercentage'),
    value: RuleAttribute.OrderDiscountPercent,
  },
  [RuleAttribute.OrderId]: { label: 'Order: ID', value: RuleAttribute.OrderId },
  [RuleAttribute.LineItemCompareAtPriceDiscountPercent]: {
    label: fmt('ruleAttributes.order.LineItemCompareAtPriceDiscountPercent'),
    value: RuleAttribute.LineItemCompareAtPriceDiscountPercent,
  },
  [RuleAttribute.OrderLineItemFulfillmentLocationId]: {
    label: fmt('ruleAttributes.order.LineItemFulfillmentLocationId'),
    value: RuleAttribute.OrderLineItemFulfillmentLocationId,
  },
  [RuleAttribute.LineItemDiscountPercent]: {
    label: fmt('ruleAttributes.order.LineItemPercentDiscounted'),
    value: RuleAttribute.LineItemDiscountPercent,
  },
  [RuleAttribute.OrderNumber]: {
    label: fmt('ruleAttributes.order.Number'),
    value: RuleAttribute.OrderNumber,
  },
  [RuleAttribute.OrderTags]: {
    label: fmt('ruleAttributes.order.Tags'),
    value: RuleAttribute.OrderTags,
  },
  [RuleAttribute.OrderTotal]: {
    label: fmt('ruleAttributes.order.Total'),
    value: RuleAttribute.OrderTotal,
  },
  [RuleAttribute.ProductId]: {
    label: fmt('ruleAttributes.Product.ID'),
    value: RuleAttribute.ProductId,
  },
  [RuleAttribute.ProductTags]: {
    label: fmt('ruleAttributes.Product.Tags'),
    value: RuleAttribute.ProductTags,
  },
  [RuleAttribute.ProductTitle]: {
    label: fmt('ruleAttributes.Product.Title'),
    value: RuleAttribute.ProductTitle,
  },
  [RuleAttribute.ProductType]: {
    label: fmt('ruleAttributes.Product.Type'),
    value: RuleAttribute.ProductType,
  },
  [RuleAttribute.VariantId]: {
    label: fmt('ruleAttributes.Product.VariantID'),
    value: RuleAttribute.VariantId,
  },
  [RuleAttribute.VariantPrice]: {
    label: fmt('ruleAttributes.Product.VariantPrice'),
    value: RuleAttribute.VariantPrice,
  },
  [RuleAttribute.VariantSku]: {
    label: fmt('ruleAttributes.Product.VariantSKU'),
    value: RuleAttribute.VariantSku,
  },
  [RuleAttribute.VariantTitle]: {
    label: fmt('ruleAttributes.Product.VariantTitle'),
    value: RuleAttribute.VariantTitle,
  },
  [RuleAttribute.VariantWeight]: {
    label: fmt('ruleAttributes.Product.VariantWeight'),
    value: RuleAttribute.VariantWeight,
  },
  [RuleAttribute.ProductVendor]: {
    label: fmt('ruleAttributes.Product.Vendor'),
    value: RuleAttribute.ProductVendor,
  },
  [RuleAttribute.ReturnReason]: {
    label: fmt('ruleAttributes.Return.Reason'),
    value: RuleAttribute.ReturnReason,
  },
  [RuleAttribute.ReturnType]: {
    label: fmt('ruleAttributes.Return.Type'),
    value: RuleAttribute.ReturnType,
  },
  [RuleAttribute.ReturnTotalValue]: {
    label: fmt('ruleAttributes.Return.TotalValue'),
    value: RuleAttribute.ReturnTotalValue,
  },
  [RuleAttribute.ReturnTotalWeightInGrams]: {
    label: fmt('ruleAttributes.Return.TotalWeightInGrams'),
    value: RuleAttribute.ReturnTotalWeightInGrams,
  },
  [RuleAttribute.ReturnTotalQuantity]: {
    label: fmt('ruleAttributes.Return.TotalQuantity'),
    value: RuleAttribute.ReturnTotalQuantity,
  },
}))

export const selectRuleActions = createSelector([selectFormatter], (fmt) => ({
  EMPTY: { label: '', value: '' },
  [RuleAction.DenyReturn]: {
    label: fmt('ruleAction.doNotAllowReturnType'),
    value: RuleAction.DenyReturn,
  },
  [RuleAction.DenyShipping]: {
    label: fmt('ruleAction.doNotAllowShippingType'),
    value: RuleAction.DenyShipping,
  },
  [RuleAction.ShipToWarehouse]: {
    label: fmt('ruleAction.shipToWarehouse'),
    value: RuleAction.ShipToWarehouse,
  },
  [RuleAction.AdjustWindow]: {
    label: fmt('ruleAction.adjustReturnWindow'),
    value: RuleAction.AdjustWindow,
  },
  [RuleAction.RequireApproval]: {
    label: fmt('ruleAction.requireWeturnApproval'),
    value: RuleAction.RequireApproval,
  },
  [RuleAction.DoNotRequireApproval]: {
    label: fmt('ruleAction.doNotRequireReturnApproval'),
    value: RuleAction.DoNotRequireApproval,
  },
  [RuleAction.SetFee]: {
    label: fmt('ruleAction.setFee'),
    value: RuleAction.SetFee,
  },
  [RuleAction.SetShippingFee]: {
    label: fmt('ruleAction.setShippingFee'),
    value: RuleAction.SetShippingFee,
  },
  [RuleAction.DoNotRequireReturnShipping]: {
    label: fmt('ruleAction.doNotRequireReturnShipping'),
    value: RuleAction.DoNotRequireReturnShipping,
  },
}))

export const selectRuleActionOptionsReturnTypes = createSelector([selectFormatter], (fmt) => ({
  EMPTY: { label: '', value: '' },
  [RuleActionOption.Refund]: {
    label: fmt('actionOptions.returnTypes.refunds'),
    value: RuleActionOption.Refund,
  },
  [RuleActionOption.Exchange]: {
    label: fmt('actionOptions.returnTypes.exchanges'),
    value: RuleActionOption.Exchange,
  },
  [RuleActionOption.Credit]: {
    label: fmt('actionOptions.returnTypes.storeCredits'),
    value: RuleActionOption.Credit,
  },
  [RuleActionOption.All]: {
    label: fmt('actionOptions.returnTypes.allReturnTypes'),
    value: RuleActionOption.All,
  },
}))

export const selectRuleActionOptionsShippingTypes = createSelector([selectFormatter], (fmt) => ({
  EMPTY: { label: '', value: '' },
  [RuleActionOption.Paid]: {
    label: fmt('actionOptions.returnTypes.customerPrePaidLabels'),
    value: RuleActionOption.Paid,
  },
  [RuleActionOption.Self]: {
    label: fmt('actionOptions.returnTypes.customerSelfShipping'),
    value: RuleActionOption.Self,
  },
  [RuleActionOption.Free]: {
    label: fmt('actionOptions.returnTypes.storePrePaidLabels'),
    value: RuleActionOption.Free,
  },
}))

const selectRuleOperators = createSelector([selectFormatter], (fmt) => ({
  empty: { label: '', value: '' },
  [RuleOperator.Eq]: { label: fmt('ruleOperator.is'), value: RuleOperator.Eq },
  [RuleOperator.Neq]: { label: fmt('ruleOperator.isNot'), value: RuleOperator.Neq },
  [RuleOperator.Lt]: { label: fmt('ruleOperator.lessThan'), value: RuleOperator.Lt },
  [RuleOperator.Gt]: { label: fmt('ruleOperator.greaterThan'), value: RuleOperator.Gt },
  [RuleOperator.Bw]: { label: fmt('ruleOperator.beginsWith'), value: RuleOperator.Bw },
  [RuleOperator.Ew]: { label: fmt('ruleOperator.endsWith'), value: RuleOperator.Ew },
  [RuleOperator.Ct]: { label: fmt('ruleOperator.contains'), value: RuleOperator.Ct },
  [RuleOperator.Nct]: { label: fmt('ruleOperator.doesNotContain'), value: RuleOperator.Nct },
}))

export const selectGetValidOperators = createSelector(
  [selectRuleOperators],
  (operators) => (attr: RuleAttribute, valueType: AttributeType) => {
    let validOperators = Object.values(operators).map((o) => o.value)
    switch (valueType) {
      case 'text':
      case 'email':
        validOperators = [
          RuleOperator.Eq,
          RuleOperator.Neq,
          RuleOperator.Bw,
          RuleOperator.Ew,
          RuleOperator.Ct,
          RuleOperator.Nct,
        ]
        break
      case 'date':
        validOperators = [RuleOperator.Eq, RuleOperator.Neq, RuleOperator.Lt, RuleOperator.Gt]
        break
      case 'country':
      case 'return-type':
        validOperators = [RuleOperator.Eq, RuleOperator.Neq]
        break
    }
    // Tags only support contains or not contains
    if ([RuleAttribute.ProductTags, RuleAttribute.CustomerTags, RuleAttribute.OrderTags].includes(attr)) {
      validOperators = [RuleOperator.Ct, RuleOperator.Nct]
    }
    return Object.values(operators).filter((o) => o.value === '' || validOperators.includes(o.value))
  },
)
