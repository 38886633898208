import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { useParams } from 'react-router-dom'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useEditNotificationPageQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

const EditNotificationPage = lazy(() => import('./EditNotificationPage'))

export const EditNotificationPageLoader: FC = () => {
  const params = useParams()
  const localeId = params.locale
  const isTranslation = !!localeId
  const [query] = useCommonData(
    useEditNotificationPageQuery,
    { variables: { includeTranslations: isTranslation } },
    'edit-notification',
  )
  const loaded = _(selectDataLoaded('edit-notification'))
  return (
    <>
      {(query.fetching || query.stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <EditNotificationPage />
      </PageRevealer>
    </>
  )
}
