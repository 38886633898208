import { Banner } from '@shopify/polaris'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _, useAppDispatch } from 'store/hooks'
import { ONBOARDING_PUNTED_STORAGE_KEY } from 'store/onboarding/onboarding.punt-storage-key'
import { setOnboardingOpen } from 'store/onboarding/onboarding.slice'

import { useCompleteOnboardingMutation } from 'gql'

export const CompleteOnboardingBanner: FC = () => {
  const fmt = _(selectFormatter)
  const dispatch = useAppDispatch()

  const [, completeOnboarding] = useCompleteOnboardingMutation()

  const [dismissed, setDismissed] = useState(sessionStorage.getItem('rz-onboarding-banner-dismissed') === 'true')
  if (dismissed) return <></>

  return (
    <Banner
      title={fmt('banners.completeOnboardingTitle')}
      tone="info"
      action={{
        content: fmt('banners.completeOnboardingButton'),
        onAction: () => dispatch(setOnboardingOpen(true)),
      }}
      secondaryAction={{
        content: fmt('banners.completeOnboardingDismiss'),
        onAction: () => {
          localStorage.removeItem(ONBOARDING_PUNTED_STORAGE_KEY)
          setDismissed(true)
          completeOnboarding({})
        },
      }}
      onDismiss={() => {
        setDismissed(true)
        sessionStorage.setItem('rz-onboarding-banner-dismissed', 'true')
      }}
    >
      <p>
        <FormattedMessage
          id="banners.completeOnboardingMessage"
          defaultMessage="You haven't completed your onboarding setup. Launch it now to configure your basic settings."
        />
      </p>
    </Banner>
  )
}
