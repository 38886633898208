import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { AuthenticatedShop, ExchangeMethod } from 'gql'

import { ONBOARDING_PUNTED_STORAGE_KEY } from './onboarding.punt-storage-key'

export interface OnboardingData {
  policies: {
    effectChange?: boolean
    refund: boolean
    exchange: boolean
    credit: boolean
  }
  exchanges: {
    effectChange?: boolean
    type: ExchangeMethod
  }
  shipping: {
    effectChange?: boolean
    prepaid: boolean
    payForLabel: boolean
    self: boolean
  }
  approval: {
    effectChange?: boolean
    type: 'immediate' | 'review'
  }
  address: {
    effectChange?: boolean
    name: string
    company: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    country: string
    postalCode: string
    phoneNumber: string
  }
}

export interface OnboardingSlice {
  open?: boolean
  punted: boolean
  initial?: OnboardingData
  current?: OnboardingData
}

const fromShopToData = (shop: Partial<AuthenticatedShop>): OnboardingData => {
  return {
    policies: {
      refund: !!shop.enableRefund,
      exchange: !!shop.enableExchange,
      credit: !!shop.enableStoreCredit,
    },
    exchanges: {
      type: (shop.subscriptionPlan && shop.exchangeMethod) || ExchangeMethod.Simple,
    },
    shipping: {
      prepaid: shop.shipFree ?? false,
      payForLabel: shop.shipPay ?? false,
      self: shop.shipSelf ?? false,
    },
    approval: {
      type: shop.useApprovals && shop.requireApprovalForAllReturns ? 'review' : 'immediate',
    },
    address: {
      name: shop.defaultAddress?.name ?? '',
      company: shop.defaultAddress?.company ?? '',
      addressLine1: shop.defaultAddress?.addressLine1 ?? '',
      addressLine2: shop.defaultAddress?.addressLine2 ?? '',
      city: shop.defaultAddress?.city ?? '',
      state: shop.defaultAddress?.state ?? '',
      country: shop.defaultAddress?.country ?? '',
      postalCode: shop.defaultAddress?.postalCode ?? '',
      phoneNumber: shop.defaultAddress?.phoneNumber ?? '',
    },
  }
}

const initialState: OnboardingSlice = {
  punted: localStorage.getItem(ONBOARDING_PUNTED_STORAGE_KEY) === 'true',
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardingOpen: (state, { payload: open }: PayloadAction<boolean>) => {
      state.open = open
    },
    setOnboardingInitialData: (state, { payload: shop }: PayloadAction<Partial<AuthenticatedShop>>) => {
      state.initial = fromShopToData(shop)
      state.current = state.initial
    },
    resetOnboardingSlide: <T extends keyof OnboardingData>(
      state: OnboardingSlice,
      { payload: slide }: PayloadAction<T>,
    ) => {
      if (state.initial && state.current) state.current[slide] = state.initial[slide]
    },
    approveSlide: (state: OnboardingSlice, { payload: slide }: PayloadAction<keyof OnboardingData>) => {
      if (state.current?.[slide]) state.current[slide].effectChange = true
    },
    setPoliciesValue: <Key extends keyof OnboardingData['policies'], Value extends OnboardingData['policies'][Key]>(
      state: OnboardingSlice,
      { payload: [key, value] }: PayloadAction<[Key, Value]>,
    ) => {
      if (state.current) state.current.policies[key] = value
    },
    setExchangeMethod: (
      state: OnboardingSlice,
      { payload: method }: PayloadAction<ExchangeMethod.Simple | ExchangeMethod.Advanced>,
    ) => {
      if (state.current) state.current.exchanges.type = method
    },
    setShippingValue: <Key extends keyof OnboardingData['shipping'], Value extends OnboardingData['shipping'][Key]>(
      state: OnboardingSlice,
      { payload: [key, value] }: PayloadAction<[Key, Value]>,
    ) => {
      if (state.current) state.current.shipping[key] = value
    },
    setApprovalValue: <Key extends keyof OnboardingData['approval'], Value extends OnboardingData['approval'][Key]>(
      state: OnboardingSlice,
      { payload: [key, value] }: PayloadAction<[Key, Value]>,
    ) => {
      if (state.current) state.current.approval[key] = value
    },
    setAddressValue: <Key extends keyof OnboardingData['address'], Value extends OnboardingData['address'][Key]>(
      state: OnboardingSlice,
      { payload: [key, value] }: PayloadAction<[Key, Value]>,
    ) => {
      if (state.current) state.current.address[key] = value
    },
  },
})

export const {
  setOnboardingOpen,
  setOnboardingInitialData,
  resetOnboardingSlide,
  approveSlide,
  setPoliciesValue,
  setExchangeMethod,
  setShippingValue,
  setApprovalValue,
  setAddressValue,
} = onboardingSlice.actions

export default onboardingSlice.reducer
