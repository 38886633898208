import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { DependencyList, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState, store } from './store'

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>
export const useAppDispatch = () => useDispatch<AppThunkDispatch>()

/**
 * Like `react-redux`'s useSelector, but it memoizes the selector,
 * thus keeping memoization enabled even for parameterized selectors
 * that would otherwise break memoization since they create a new
 * selector function on every render.
 *
 * Pass a deps array if you want to be able to update the selector.
 */
export const _ = <Selected = unknown>(selector: (state: RootState) => Selected, deps?: DependencyList) =>
  useSelector(useCallback(selector, deps ?? []))

export type AppDispatch = typeof store.dispatch
