import { RuleAttribute, ShopsPackageUnitChoices, ShopsPackageWeightUnitChoices } from 'gql'

// Named myconstants instead of constants cuz of node/no-deprecated-api

export const MEASURE_UNITS = [
  { label: 'cm', value: ShopsPackageUnitChoices.Cm },
  { label: 'in', value: ShopsPackageUnitChoices.In },
]
export const WEIGHT_UNITS = {
  [ShopsPackageWeightUnitChoices.Grams]: {
    label: 'g',
    value: ShopsPackageWeightUnitChoices.Grams,
  },
  [ShopsPackageWeightUnitChoices.Kilograms]: {
    label: 'kg',
    value: ShopsPackageWeightUnitChoices.Kilograms,
  },
  [ShopsPackageWeightUnitChoices.Ounces]: {
    label: 'oz',
    value: ShopsPackageWeightUnitChoices.Ounces,
  },
  [ShopsPackageWeightUnitChoices.Pounds]: {
    label: 'lb',
    value: ShopsPackageWeightUnitChoices.Pounds,
  },
}

export const SUBSCRIPTION_TYPES = {
  FREE: 0,
  BASIC: 3,
  STANDARD: 1,
  PRO: 2,
  PLUS: 4,
  ENTERPRISE: 5,
}
export type SubscriptionDisplayType = keyof typeof SUBSCRIPTION_TYPES
export type SubscriptionType = (typeof SUBSCRIPTION_TYPES)[SubscriptionDisplayType]

export type AttributeType = 'text' | 'number' | 'email' | 'date' | 'country' | 'return-type' | 'return-reason'
export const getAttributeType = (attr: RuleAttribute): AttributeType => {
  if (attr === RuleAttribute.CustomerCountry) return 'country'
  if (attr === RuleAttribute.CustomerEmail) return 'email'
  if (
    [
      RuleAttribute.CustomerId,
      RuleAttribute.CustomerLtv,
      RuleAttribute.CustomerNumOrders,
      RuleAttribute.LineItemCompareAtPriceDiscountPercent,
      RuleAttribute.LineItemDaysSinceDeliveredAt,
      RuleAttribute.LineItemDiscountPercent,
      RuleAttribute.OrderDaysSinceOrdered,
      RuleAttribute.OrderDiscountPercent,
      RuleAttribute.OrderId,
      RuleAttribute.OrderTotal,
      RuleAttribute.ProductId,
      RuleAttribute.ReturnTotalQuantity,
      RuleAttribute.ReturnTotalValue,
      RuleAttribute.ReturnTotalWeightInGrams,
      RuleAttribute.VariantId,
      RuleAttribute.VariantPrice,
      RuleAttribute.VariantWeight,
    ].includes(attr)
  )
    return 'number'
  if ([RuleAttribute.CustomerTags, RuleAttribute.OrderTags, RuleAttribute.ProductTags].includes(attr)) return 'text'
  if (attr === RuleAttribute.OrderDate) return 'date'
  if (attr === RuleAttribute.ReturnType) return 'return-type'
  if (attr === RuleAttribute.ReturnReason) return 'return-reason'
  return 'text'
}
